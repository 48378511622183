import React, { useState, useEffect } from 'react';
import {
    Fab,
    Menu,
    MenuItem,
    Box,
    Alert,
    Snackbar
} from '@mui/material';
import { Share, Facebook, Twitter, WhatsApp } from '@mui/icons-material';
import { LocalStorageContext } from '../utils/localStorage';
import Cookies from 'js-cookie';
import { useDrama } from '../contexts/DramaContext';
import ReactGA from 'react-ga4';

const ShareButton = ({dramaId, url, title, onShareSuccess,viewModel }) => {


    const {addShareCredits} = viewModel;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const { setSignInModalOpen } = useDrama();

    const handleClick = (event) => {
        const sessionId = Cookies.get('sessionId');
        if (!sessionId) {
            setSignInModalOpen(true);
            return;
        }
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const generateShareTitle = (title) => {
        return "I saw a drama called '" + title + "' on BingeShorts, It's so good😊😊😊, you should check it out🥰🥰🥰!"
    }

    const shareToFacebook = () => {
        const shareUrl = `https://www.facebook.com/sharer.php?` +
            `u=${encodeURIComponent(url)}` +
            `&quote=${encodeURIComponent(generateShareTitle(title))}` +
            `&hashtag=${encodeURIComponent('#BingeShorts Drama')}`;
        console.log(shareUrl);
        openShareWindow(shareUrl, 'Facebook');
    };

    const shareToTwitter = () => {
        const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(generateShareTitle(title))}`;
        openShareWindow(shareUrl, 'Twitter');
    };

    const shareToWhatsApp = () => {
        const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(generateShareTitle(title) + ' ' + url)}`;
        openShareWindow(shareUrl, 'WhatsApp');
    };

    const openShareWindow = (url, platform) => {
        ReactGA.event({
            category: 'Interaction',
            action: 'Share',
            label: `Shared to ${platform} video ${title}`
        });
        const width = 600;
        const height = 400;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;

        const shareWindow = window.open(
            url,
            `Share on ${platform}`,
            `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
        );

        const checkWindow = setInterval(() => {
            if (shareWindow.closed) {
                clearInterval(checkWindow);
                onShareSuccess && onShareSuccess(platform);
                addShareCredits(dramaId, platform);
                handleClose();
            }
        }, 1000);
    };

    // 在组件加载时检查是否需要显示提示
    useEffect(() => {
        const hasSeenTip = LocalStorageContext.getItem('shareButtonTipSeen');
        if (!hasSeenTip) {
            const timer = setTimeout(() => {
                setShowTip(true);
            }, 10000); // 延迟10秒显示
            return () => clearTimeout(timer);
        }
    }, []);

    const [showTip, setShowTip] = useState(false);

    const handleIKnow = () => {
        setShowTip(false);
        LocalStorageContext.setItem('shareButtonTipSeen', true);
    };

    return (
        <Box style={{ position: 'relative' }}>
            <Fab
                color="primary"
                aria-label="share"
                size='medium'
                onClick={handleClick}
            >
                <Share />
            </Fab>
            <Snackbar open={showTip}
                autoHideDuration={5000}
                onClose={(event, reason) => {
                    console.log(reason);
                    if (reason === 'clickaway') return;
                    if (reason === 'timeout') {
                        setShowTip(false);
                        return;
                    }
                    handleIKnow();
                }}
                sx={{
                    position: 'absolute',
                    width: '400px',
                    left: '50%',
                    transform: 'translateX(-90%)',  // 水平居中
                    top: '80px',  // Fab 按钮的高度 + 一些间距
                    '& .MuiAlert-root': {
                        width: '100%'
                    }
                }}>
                <Alert
                    onClose={handleIKnow}
                    severity="info"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Share with friends and earn 10 credits for each platform per drama!
                </Alert>
            </Snackbar>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={shareToFacebook}>
                    <Facebook sx={{ mr: 1 }} /> Facebook
                </MenuItem>
                <MenuItem onClick={shareToTwitter}>
                    <Twitter sx={{ mr: 1 }} /> Twitter
                </MenuItem>
                <MenuItem onClick={shareToWhatsApp}>
                    <WhatsApp sx={{ mr: 1 }} /> WhatsApp
                </MenuItem>
            </Menu>

        </Box>
    );
};

export default ShareButton;