import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { DramaProvider } from './contexts/DramaContext';
import HomePage from './pages/HomePage';
import ReactGA from 'react-ga4';

// 在组件外初始化GA
ReactGA.initialize("G-V8GT428NTG", {
  gaOptions: {
    sendPageView: false  // 避免重复的页面访问统计
  }
});

function App() {

  return (
    <DramaProvider>
      <Routes>
        <Route path="/drama/:dramaId?/*" element={<HomePage />} />
        <Route path="/*" element={<HomePage />} />
      </Routes>
    </DramaProvider>
  );
}

export default App;
