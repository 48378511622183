import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, Box, Fab, Card, CardContent, Typography, IconButton, Divider, Button, List, ListItem, ListItemAvatar, Avatar, ListItemText, Grid2, Drawer, Fade, Paper } from '@mui/material';
import { ThumbUp, ChevronLeft, ChevronRight, WatchLater, KeyboardDoubleArrowUp, KeyboardDoubleArrowDown, Lock } from '@mui/icons-material';
import VideoPlayer from './VideoPlayer';
import styled from '@emotion/styled';
import Cookies from 'js-cookie';
import { useDrama } from '../contexts/DramaContext';
import { useDocumentMeta } from '../hooks/UseDocumentMeta';
import { LocalStorageContext } from '../utils/localStorage'
import { format, parseISO } from 'date-fns';
import ShareButton from './ShareButton';
import ReactGA from 'react-ga4';

// 样式组件
const VideoContainer = styled(Card, {
    shouldForwardProp: (prop) => !['isExpanded', 'slideDirection', 'matches'].includes(prop)
})(({ isExpanded, matches }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    margin: matches ? 0 : 8,
    width: isExpanded ? '100%' : '80%',
    transition: 'width 0.3s',
    backgroundColor: "#2b2b2b",
    borderRadius: matches ? 0 : 4
}));

const FloatingButtons = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: 16,
    top: '50%',
    transform: 'translateY(-50%)',
    gap: '16px',
    zIndex: 2
});

const keyframes = `
@keyframes upDown {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
}
`;

const VideoContent = ({ viewModel }) => {

    // 使用useMediaQuery来检测屏幕宽度
    const matches = useMediaQuery('(max-width:600px)');

    function importAll(r) {
        return r.keys().map(r);
    }

    const avatarImages = importAll(require.context('../assets/images/avatars', false, /\.(png|jpe?g|svg)$/));

    function getRandomAvatarImage() {
        return avatarImages[Math.floor(Math.random() * avatarImages.length)];
    }

    const {
        watchLater,
        fetchUserDetail,
        fetchDramas,
        fetchSingleDrama,
        fetchEpisodes,
        fetchEpisodeDetail,
        buyEpisode,
        fetchComments,
    } = viewModel;

    const navigate = useNavigate();
    const { setDocumentMeta } = useDocumentMeta();

    const pageSize = 3
    const { currentDramaId,
        setShowPaymentDialog,
        dramas, setDramas,
        currentEpisodes, setCurrentEpisodes,
        setCurrentEpisodeDetail,
        currentPage, setCurrentPage,
        currentPlayTime,
        userDetail, setUserDetail, setSignInModalOpen,
        comments, setComments, activeIndex, swiperInstance } = useDrama();

    const [isExpanded, setIsExpanded] = useState(false);
    const [currentEpisodeIndex, setCurrentEpisodeIndex] = useState(0);

    const videoContainerRef = useRef(null);

    const expandRef = useRef();
    expandRef.current = isExpanded;
    const episodesRef = useRef();
    episodesRef.current = currentEpisodes;
    const episodeIndexRef = useRef();
    episodeIndexRef.current = currentEpisodeIndex;

    const [showSwipeGuide, setShowSwipeGuide] = useState(false);

    const updateUserDetail = async () => {
        const sessionId = Cookies.get('sessionId')
        if (!sessionId) {
            return
        }
        const detail = await fetchUserDetail()
        if (detail) {
            setUserDetail(detail)
        }
    }

    useEffect(() => {
        console.log("activeIndex dramas change", activeIndex,dramas)
        if(activeIndex === 0 && dramas.length === 0){
            listDramas(1,0)
            return
        }
        setCurrentEpisodeIndex(0)
        if (dramas.length > activeIndex) {
            (async () => {
                const currentDrama = dramas[activeIndex]
                const episodes = await fetchEpisodes(currentDrama.dramaId);
                setCurrentEpisodes(episodes);
                const newPath = `/drama/${currentDrama.dramaId}`;
                navigate(newPath, { replace: true });
                setDocumentMeta({
                    title: `${currentDrama.name} | BingeShorts Drama Video`,
                    description: currentDrama.desc
                });
            })();
            (async () => {
                if (dramas[activeIndex]) {
                    const commontsRes = await fetchComments(dramas[activeIndex].dramaId)
                    setComments(commontsRes.data)
                }
            })();
        }
    }, [activeIndex]);

    useEffect(() => {
        console.log("dramaid jump", currentDramaId)
        if (currentDramaId) {
            // 定义一个立即执行的异步函数
            (async () => {
                const dramaData = await fetchSingleDrama(currentDramaId)
                if (dramaData) {
                    const newDrama = dramaData
                    setDramas((prevDramas) => {
                        if (prevDramas[activeIndex].dramaId === newDrama.dramaId) {
                            return prevDramas;
                        }
                        for (const [index, item] of prevDramas.entries()) {
                            if (item.dramaId === newDrama.dramaId) {
                                swiperInstance.slideTo(index)
                                return prevDramas
                            }
                        }
                        return [
                            ...prevDramas.slice(0, activeIndex),
                            newDrama,
                            ...prevDramas.slice(activeIndex)
                        ]
                    })
                    const episodes = await fetchEpisodes(newDrama.dramaId);
                    setCurrentEpisodes(episodes);
                }
            })();
        }
    }, [currentDramaId]);


    useEffect(() => {
        if(currentEpisodes && currentEpisodes.dramaDetails && currentEpisodes.dramaDetails.length > 0){
            const detail = currentEpisodes.dramaDetails[currentEpisodeIndex]
            detail['dramaUrl'] = dramas[activeIndex].firstOrderDramaUrl
            detail['subtitles'] = dramas[activeIndex].firstOrderSubtitles
            detail['imgUrl'] = currentEpisodes.imgUrl
            const mockDetail = dramas[activeIndex]
            mockDetail['dramaName'] = currentEpisodes.name
            mockDetail['dramaDetailId'] = detail.id + ""
            mockDetail['dramaDetail'] = detail
            setCurrentEpisodeDetail(mockDetail)
        }
    }, [currentEpisodes,currentEpisodeIndex,dramas])

    const listDramas = async (pageNo, dramaIndex = 0) => {
        console.log("listDramas", pageNo)
        const dramaData = await fetchDramas(pageNo, pageSize)
        if (dramaData && dramaData.data && dramaData.data.length > 0) {
            setDramas((prevDramas) => [...prevDramas, ...dramaData.data]);
            if (dramaIndex === -1) {
                return
            }
            const drama = dramaData.data[dramaIndex]
            const episodesData = await fetchEpisodes(drama.dramaId)
            setCurrentEpisodes(episodesData)
        }
    }

    const replaceCurrentEp = (dramaUrl, subtitlesUrl) => {
        setDramas((prevDramas) => {
            const newDrama = prevDramas[activeIndex]
            newDrama.firstOrderDramaUrl = dramaUrl
            newDrama.firstOrderSubtitles = subtitlesUrl
            return  [
                ...prevDramas.slice(0, activeIndex),
                newDrama,
                ...prevDramas.slice(activeIndex)
            ];
        })
    }

    const selectEpisode = async (index) => {
        const curEps = episodesRef.current
        const episode = curEps.dramaDetails[index]
        const episodeDetail = await fetchEpisodeDetail(episode.id)
        if (episodeDetail && !episodeDetail.dramaUrl) {
            updateUserDetail()
            const sessionId = Cookies.get('sessionId')
            if (!sessionId) {
                setSignInModalOpen(true)
                return
            }
            if (userDetail && userDetail.scoreCount > 0) {
                const buyDetail = await buyEpisode(episodeDetail.id)
                console.log("buyDetail", buyDetail)
                if (buyDetail) {
                    replaceCurrentEp(buyDetail.dramaUrl, buyDetail.subtitles)
                    return
                }
            }
            setShowPaymentDialog(true)
            return
        }
        replaceCurrentEp(episodeDetail.dramaUrl, episodeDetail.subtitles)
    }

    const isLogin = () => {
        return Cookies.get('sessionId') !== undefined
    }

    const onWatchLater = () => {
        // 添加GA跟踪
        ReactGA.event({
            category: 'Interaction',
            action: 'Watch Later',
            label: `Added to watch later: ${dramas[activeIndex]?.name}`
        });

        if (isLogin()) {
            watchLater(currentEpisodes.dramaDetails[currentEpisodeIndex].id, currentPlayTime)
        } else {
            if(currentEpisodes && currentEpisodes.dramaDetails && currentEpisodes.dramaDetails.length > 0){
                const detail = currentEpisodes.dramaDetails[currentEpisodeIndex]
                detail['dramaUrl'] = dramas[activeIndex].firstOrderDramaUrl
                detail['subtitles'] = dramas[activeIndex].firstOrderSubtitles
                detail['imgUrl'] = currentEpisodes.imgUrl
                const mockDetail = dramas[activeIndex]
                mockDetail['dramaName'] = currentEpisodes.name
                mockDetail['dramaDetailId'] = detail.id + ""
                mockDetail['dramaDetail'] = detail
                LocalStorageContext.pushItem("watchLater", mockDetail)
            }
        }
    }

    const toggleExpand = () => {
        // 添加GA跟踪
        ReactGA.event({
            category: 'UI',
            action: 'Toggle Expand',
            label: `${!isExpanded ? 'Expanded' : 'Collapsed'} video panel`
        });
        setIsExpanded(() => !isExpanded);
    };

    const playEpisode = (index) => {
        // 添加GA跟踪
        ReactGA.event({
            category: 'Video',
            action: 'Episode Select',
            label: `Selected episode ${index + 1} of ${dramas[activeIndex]?.name}`
        });
        
        setCurrentEpisodeIndex(index)
        selectEpisode(index)
    };

    const loadMoreDrama = async () => {
        const page = currentPage + 1
        setCurrentPage(page)
        listDramas(page, -1)
    }

    const playEnd = () => {
        const eps = episodesRef.current
        const nextEpIndex = episodeIndexRef.current + 1
        if (nextEpIndex >= eps.dramaDetails.length) {
            return
        }
        selectEpisode(nextEpIndex)
        setCurrentEpisodeIndex((prev) => prev + 1)
    }

    // 在控制栏中添加分享按钮:
    const handleShareSuccess = (platform) => {
        console.log(`Successfully shared to ${platform}`);
        // 这里可以添加分享成功后的其他操作
    };

    const sideBarContent = () => {
        return (
            <Box role="presentation" sx={{
                width: matches ? '100%' : '18%',
                height: '100%',
                marginLeft: matches ? 1 : 2,
                marginRight: matches ? 1 : 2,
                marginTop: matches ? 1 : 1,
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                overflowY: 'auto'
            }}>
                <Card sx={{ maxHeight: '70%', overflow: 'hidden', display: 'flex', flexDirection: 'column', backgroundColor: "#2b2b2b" }}>
                    {currentEpisodes && currentEpisodes.dramaDetails && currentEpisodes.dramaDetails.length > 0 && (
                        <CardContent sx={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h6" sx={{ display: 'flex', color: 'white' }}>{currentEpisodes.name}</Typography>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{
                                    flexBasis: '36%',  // 图片占据30%的宽度
                                    flexShrink: 0
                                }}>
                                    <img src={currentEpisodes.imgUrl} alt="drama thumbnail" style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover'  // 保持图片比例并填充容器
                                    }} />
                                </Box>
                                <Box sx={{
                                    flexGrow: 1,  // 文字部分占据剩余空间
                                    overflow: 'hidden'
                                }}>
                                    <Typography variant="body2" sx={{
                                        color: 'white',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 5,  // 限制最多显示5行
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        paddingLeft: 2
                                    }}>
                                        {currentEpisodes.desc}
                                    </Typography>
                                </Box>
                            </Box>
                            <Divider sx={{ marginTop: 2, marginBottom: 1 }} />
                            <Typography variant="h6" sx={{ color: 'white' }}>Episode List</Typography>
                            <Box sx={{ overflow: 'auto', flexGrow: 1 }}>
                                <Grid2 container spacing={1}>
                                    {currentEpisodes && currentEpisodes.dramaDetails && currentEpisodes.dramaDetails.map((episode, index) => (
                                        <Grid2 key={index}>
                                            <Button
                                                size="small"
                                                variant={currentEpisodeIndex === index ? "contained" : "outlined"}
                                                onClick={() => playEpisode(index)}
                                                sx={{ display: 'flex', position: 'relative' }}>
                                                {episode.order}
                                                {episode.dramaUrl === null && <Lock sx={{ position: 'absolute', top: 2, right: 2, fontSize: 12 }} />}
                                            </Button>
                                        </Grid2>
                                    ))}
                                </Grid2>
                            </Box>
                        </CardContent>
                    )}
                </Card>

                <Card sx={{ minHeight: '30%', maxHeight: '30%', marginTop: 2, marginBottom: 2, display: 'flex', flexDirection: 'column', backgroundColor: "#2b2b2b" }}>
                    <CardContent sx={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h6" sx={{ color: 'white' }}>Comments</Typography>
                        <Divider />
                        <List sx={{ overflow: 'auto', flexGrow: 1 }}>
                            {Array.isArray(comments) && comments.map((comment, index) => (
                                <ListItem key={index} alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar src={getRandomAvatarImage()} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{ color: 'white' }}
                                        primary={comment.commentStr}
                                        secondary={
                                            <Typography variant="caption" color="rgba(255,255,255,0.4)">
                                                {comment.createdAt && format(parseISO(comment.createdAt), 'yyyy-MM-dd HH:mm')}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </Card>
            </Box>
        );
    };

    useEffect(() => {
        const hasShown = localStorage.getItem('hasShownSwipeGuide');
        if (!hasShown) {
            const showTimer = setTimeout(() => {
                setShowSwipeGuide(true);
            }, 5000); // 延迟5秒显示
            return () => clearTimeout(showTimer);
        }
    }, []);

    useEffect(() => {
        if (showSwipeGuide) {
            const hideTimer = setTimeout(() => {
                setShowSwipeGuide(false);
                localStorage.setItem('hasShownSwipeGuide', 'true');
            }, 3000);
            return () => clearTimeout(hideTimer);
        }
    }, [showSwipeGuide]);

    useEffect(() => {
        const style = document.createElement('style');
        style.innerHTML = keyframes;
        document.head.appendChild(style);
        return () => document.head.removeChild(style);
    }, []);

    return (
        <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
            <VideoContainer isExpanded={isExpanded} matches={matches} ref={videoContainerRef}>
                {Array.isArray(dramas) && (<VideoPlayer viewModel={viewModel} playEnd={playEnd} loadMoreDrama={loadMoreDrama} ratio={9 / 16} />)}
                <FloatingButtons>
                    <Fab 
                        color="error" 
                        aria-label="like" 
                        size='medium'
                        onClick={() => {
                            ReactGA.event({
                                category: 'Interaction',
                                action: 'Like',
                                label: `Liked video ${dramas[activeIndex]?.name}`
                            });
                        }}
                    >
                        <ThumbUp />
                    </Fab>
                    <Fab color="secondary" aria-label="toggle-expand" size='medium' onClick={toggleExpand}>
                        {isExpanded ? <ChevronRight size='medium' /> : <ChevronLeft size='medium' />}
                    </Fab>
                    <Fab color="default" aria-label="watch-later" size='medium'>
                        <WatchLater onClick={onWatchLater} />
                    </Fab>
                    <ShareButton 
                        viewModel={viewModel}
                        dramaId={dramas[activeIndex]?.dramaId}
                        url={window.location.href}
                        title={dramas[activeIndex]?.name || ''}
                        onShareSuccess={handleShareSuccess}
                    />
                </FloatingButtons>
                <Fade in={showSwipeGuide}>
                    <Paper
                        elevation={3}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            color: 'white',
                            padding: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            zIndex: 1000,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 1
                        }}
                    >
                        <KeyboardDoubleArrowUp sx={{ animation: 'upDown 1.5s infinite' }} />
                        <Typography>
                            Swipe Up/Down to Switch Dramas
                        </Typography>
                        <KeyboardDoubleArrowDown sx={{ animation: 'upDown 1.5s infinite' }} />
                    </Paper>
                </Fade>
            </VideoContainer>
            {isExpanded ? (matches ?
                <Drawer
                    anchor='right'
                    open={isExpanded}
                    onClose={toggleExpand}
                    sx={{
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: '84%',
                            backgroundColor: "#1c1c1c"
                        },
                    }}> {sideBarContent()}
                </Drawer> : sideBarContent()) : null}

            {!matches && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: "#1c1c1c" }}>
                <IconButton size='small' onClick={() => { if (swiperInstance) { swiperInstance.slidePrev(); } }}>
                    <KeyboardDoubleArrowUp sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                </IconButton>
                <IconButton size='small' onClick={() => { if (swiperInstance) { swiperInstance.slideNext(); } }}>
                    <KeyboardDoubleArrowDown sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                </IconButton>
            </Box>}
        </Box>
    );
};

export default VideoContent;
