import React,{useEffect,useState} from 'react';
import { useParams } from 'react-router-dom';
import { useDrama } from '../contexts/DramaContext';
import { useDocumentMeta } from '../hooks/UseDocumentMeta';
import ShowContent from './ShowContent';
import CategoryContent from './CategoryContent';
import SubscribeContent from './SubscribeContent';
import ContactPage from './ContactUsContent';
import VideoContent from './VideoContent';

const MainContent = ({ viewModel, contentType }) => {
  let title, description;

  const { dramaId } = useParams();
  const { setCurrentDramaId, dramas } = useDrama();
  const [dramaTitle, setDramaTitle] = useState('');
  const [dramaDescription, setDramaDescription] = useState('');

  useEffect(() => {
    if (dramaId && contentType === 'Hot') {
      setCurrentDramaId(dramaId);
      const currentDrama = dramas.find(drama => drama.dramaId === dramaId);
      if (currentDrama) {
        setDramaTitle(currentDrama.name);
        setDramaDescription(currentDrama.desc);
      }
    }
  }, [dramaId, contentType, setCurrentDramaId, dramas]);

  if (dramaId) {
    title = `${dramaTitle} | BingeShorts Video`;
    description = dramaDescription;
  } else {
    switch (contentType) {
      case 'Hot':
        title = 'Hot Dramas | BingeShorts Video';
        description = 'Discover the hottest dramas trending right now.';
        break;
      case 'Updates':
        title = 'Latest Updates | BingeShorts';
        description = 'Stay up to date with the latest drama episodes and releases.';
        break;
      case 'Category':
        title = 'Drama Categories | BingeShorts';
        description = 'Explore dramas by genre and category.';
        break;
      case 'Subscriptions':
        title = 'Your Subscriptions | BingeShorts';
        description = 'Manage and watch your subscribed dramas.';
        break;
      case 'Contact':
        title = 'Contact Us | BingeShorts';
        description = 'Get in touch with us for any inquiries or feedback.';
        break;
      default:
        title = 'BingeShorts';
        description = 'Watch the best cdramas online.';
    }
  }

  useDocumentMeta(title, description);

  switch (contentType) {
    case 'Hot':
      return <VideoContent viewModel={viewModel} />;
    case 'Updates':
      return <ShowContent viewModel={viewModel} />;
    case 'Category':
      return <CategoryContent viewModel={viewModel} />;
    case 'Subscriptions':
      return <SubscribeContent viewModel={viewModel} />;
    case 'Contact':
      return <ContactPage viewModel={viewModel}/>;
    default:
      return <VideoContent viewModel={viewModel} />;
  }
};

export default MainContent;
