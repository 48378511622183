import { useState } from 'react';
import api from '../utils/api';
import Cookies from 'js-cookie';
import { useDrama } from '../contexts/DramaContext';

const ViewModel = (useMockData) => {

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { setSignInModalOpen } = useDrama();


  const parseResponse = (response) => {
    if (response.status !== 200) {
      throw new Error(response.statusText || 'unknow error');
    }
    const data = response.data
    if (data.success === true) {
      return data.data;
    } else if (data.code === -1 && data.msg === "NOT_LOGIN") {
      Cookies.remove('sessionId')
      setSignInModalOpen(true);
      throw new Error('please login');
    } else {
      throw new Error(data.msg || 'unknow error');
    }
  };

  const signWithGoogleSuccess = async (token) => {
    try {
      setIsLoading(true);
      const response = await api.get(`/user/auth/google/${token}`);
      const data = parseResponse(response);
      return data
    } catch (err) {
      setError(err.message);
      return null;
    } finally {
      setIsLoading(false);
    }
  };


  const fetchUserDetail = async () => {
    if (useMockData) {
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const sessionId = Cookies.get('sessionId');
        const headers = {};

        if (sessionId) {
          headers['Authorization'] = sessionId;
        }
        const response = await api.get('/user/getUserDetail', { headers });
        if (response.status === 200) {
          const data = response.data
          console.log(data)
          if(data.msg === 'NOT_LOGIN'){
            Cookies.remove('sessionId')
            return null
          }
          if (data.success === true) {
            return data.data;
          } 
        }
        return null
      } catch (err) {
        setError(err.message);
        return null;
      } finally {
        setIsLoading(false);
      }
    }
  };

  const reportPlayRecord = async (epId, time) => {
    try {
      setIsLoading(true);
      const sessionId = Cookies.get('sessionId');
      const headers = {};

      if (sessionId) {
        headers['Authorization'] = sessionId;
      }
      const body = {
        "historyType": 0,
        "dramaDetailId": epId,
        "time": time
      }
      const response = await api.post('/drama/addWatchHistory', body, { headers });
      return parseResponse(response);
    } catch (err) {
      setError(err.message);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const watchLater = async (epId, time) => {
    console.log("watchLater",epId,time)
    try {
      setIsLoading(true);
      const sessionId = Cookies.get('sessionId');
      const headers = {};

      if (sessionId) {
        headers['Authorization'] = sessionId;
      }
      const body = {
        "dramaDetailId": epId,
        "historyType": 1,
        "watchTime": time
      }
      const response = await api.post('/drama/addWatchHistory', body, { headers });
      return parseResponse(response);
    } catch (err) {
      setError(err.message);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const subscribeDrama = async (dramaName) => {
    try {
      setIsLoading(true);
      const sessionId = Cookies.get('sessionId');
      const headers = {};

      if (sessionId) {
        headers['Authorization'] = sessionId;
      }
      const body = {
        "historyType": 2,
        "dramaName": dramaName
      }
      const response = await api.post('/drama/addWatchHistory', body, { headers });
      return parseResponse(response);
    } catch (err) {
      setError(err.message);
      return null;
    } finally {
      setIsLoading(false);
    }
  };


  const fetchWaitchHistoryItems = async () => {
    if (useMockData) {
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const sessionId = Cookies.get('sessionId');
        const headers = {};

        if (sessionId) {
          headers['Authorization'] = sessionId;
        }
        const response = await api.post('/drama/getWatchHistory',{}, { headers });
        const data = parseResponse(response);
        const arr = data.data
        if(arr && arr.length > 0){
          arr.forEach(item => {
            if(item.dramaDetail && item.dramaDetail.imgUrl){
              item['imgUrl'] = item.dramaDetail.imgUrl
            }
          });
        }
        return arr;
      } catch (err) {
        setError(err.message);
        return [];
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchWaitchLaterItems = async () => {
    if (useMockData) {
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const sessionId = Cookies.get('sessionId');
        const headers = {};

        if (sessionId) {
          headers['Authorization'] = sessionId;
        }
        const response = await api.get('/waitchLaterItems', { headers });
        const data = parseResponse(response);
        return data;
      } catch (err) {
        setError(err.message);
        return [];
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchDramas = async (pageNo, pageSize, keyword,tag,dramaId) => {
    if (useMockData) {
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        let request = {
          'page': pageNo,
          'pageSize': pageSize
        }
        if(dramaId){
          request['id'] = dramaId;
        }
        if (keyword) {
          request['dramaName'] = keyword;
        }
        if(tag){
          request['tag'] = tag;
        }
        const sessionId = Cookies.get('sessionId');
        const headers = {};

        if (sessionId) {
          headers['Authorization'] = sessionId;
        }
        const response = await api.post('/drama/list', request, { headers });
        const data = parseResponse(response);
        return data;
      } catch (err) {
        setError(err.message);
        return [];
      } finally {
        setIsLoading(false);
      }
    }
  };

  
  const fetchSingleDrama = async (dramaId) => {
    if (useMockData) {
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const sessionId = Cookies.get('sessionId');
        const headers = {};

        if (sessionId) {
          headers['Authorization'] = sessionId;
        }
        const response = await api.get(`/drama/getDrama/${dramaId}`, { headers });
        const data = parseResponse(response);
        if(data.dramaDetails && data.dramaDetails.length > 0){
          
          const detail = await fetchEpisodeDetail(data.dramaDetails[0].id)
          data['firstOrderDramaUrl'] = detail['dramaUrl']
          data['firstOrderSubtitles'] = detail['subtitles']
        }
        return data;
      } catch (err) {
        setError(err.message);
        return [];
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchEpisodes = async (show_id) => {
    if (useMockData) {
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const sessionId = Cookies.get('sessionId');
        const headers = {};

        if (sessionId) {
          headers['Authorization'] = sessionId;
        }
        const response = await api.get(`/drama/getDrama/${show_id}`, { headers });
        const data = parseResponse(response);
        return data;
      } catch (err) {
        setError(prev => ({ ...prev, episodes: err.message }));
        return null;
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchEpisodeDetail = async (episode_id) => {
    if (useMockData) {
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const sessionId = Cookies.get('sessionId');
        const headers = {};

        if (sessionId) {
          headers['Authorization'] = sessionId;
        }
        const response = await api.get(`/drama/getDramaDetail/${episode_id}`, { headers });
        const data = parseResponse(response);
        return data;
      } catch (err) {
        setError(prev => ({ ...prev, episodes: err.message }));
        return null;
      } finally {
        setIsLoading(false);
      }
    }
  };

  const comment = async (dramaDetailId,content) => {
    if (useMockData) {
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const sessionId = Cookies.get('sessionId');
        const headers = {};

        if (sessionId !== undefined) {
          headers['Authorization'] = sessionId;
        }
        let request = {
          "dramaDetailId": dramaDetailId,
          "commentStr": content,
      }
        const response = await api.post('/drama/comment/addComment', request, { headers });
        const data = parseResponse(response);
        return data;
      } catch (err) {
        setError(err.message);
        return null;
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchComments = async (dramaDetailId) => {
    if (useMockData) {
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const sessionId = Cookies.get('sessionId');
        const headers = {};

        if (sessionId) {
          headers['Authorization'] = sessionId;
        }
        const response = await api.post('/drama/comment/getCommentList',  {"dramaDetailId":dramaDetailId}, { headers });
        const data = parseResponse(response);
        return data;
      } catch (err) {
        setError(err.message);
        return null;
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchSkuInfo = async () => {
    try {
      setIsLoading(true);
      const sessionId = Cookies.get('sessionId');
      const headers = {};

      if (sessionId) {
        headers['Authorization'] = sessionId;
      }
      const response = await api.get('/order/getAllSku', { headers });
      const data = parseResponse(response);
      return data.filter(item => item.skuId !== '4' && item.skuId !== '5');
    } catch (err) {
      setError(err.message);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const createInnerOrder = async (skuId,pointAmount) => {
    try {
      setIsLoading(true);
      const sessionId = Cookies.get('sessionId');
      const headers = {};

      if (sessionId) {
        headers['Authorization'] = sessionId;
      }
      let request = {
        'skuId': skuId,
      }
      if(pointAmount && skuId === '3'){
        request['skuNum'] = pointAmount
      }
      const response = await api.post('/order/createOrder', request, { headers });
      const data = parseResponse(response);
      return data
    } catch (err) {
      setError(err.message);
      return null
    } finally {
      setIsLoading(false);
    }
  }

  const checkOrder = async (orderId) => {
    try {
      setIsLoading(true);
      const sessionId = Cookies.get('sessionId');
      const headers = {};

      if (sessionId) {
        headers['Authorization'] = sessionId;
      }
      let request = {
        'outOrderId': orderId,
        "outPayTypeEnum": "PAY_PAL"
      }
      const response = await api.post('/order/authOrder', request, { headers });
      const data = parseResponse(response);
      return data
    } catch (err) {
      setError(err.message);
      return null
    } finally {
      setIsLoading(false);
    }
  }


  const buyEpisode = async (dramaDetailId) => {
    try {
      setIsLoading(true);
      const sessionId = Cookies.get('sessionId');
      const headers = {};

      if (sessionId) {
        headers['Authorization'] = sessionId;
      }
      let request = {
        "skuId":"4",
        "dramaDetailId": dramaDetailId
    }
      const response = await api.post('/order/createOrder', request, { headers });
      parseResponse(response);
      const detailRes = await fetchEpisodeDetail(dramaDetailId)
      return detailRes
    } catch (err) {
      setError(err.message);
      return null
    } finally {
      setIsLoading(false);
    }
  }

  const addShareCredits = async (dramaId, platform) => {
    try {
      setIsLoading(true);
      const sessionId = Cookies.get('sessionId');
      const headers = {};

      if (sessionId) {
        headers['Authorization'] = sessionId;
      }
      let request = {
        'skuId': '5',
        'dramaId': dramaId,
      }
      if(platform === 'Facebook'){
        request['shareTypeEnum'] = 'FACEBOOK'
      }
      if(platform === 'WhatsApp'){
        request['shareTypeEnum'] = 'WATCHSAPP'
      }
      if(platform === 'Twitter'){
        request['shareTypeEnum'] = 'TWITTER'
      }
      console.log(request)
      const response = await api.post('/order/createOrder', request, { headers });
      const data = parseResponse(response);
      return data
    } catch (err) {
      setError(err.message);
      return null;
    } finally {
      setIsLoading(false);
    }
  }

  return {
    isLoading,
    error,
    signWithGoogleSuccess,
    reportPlayRecord,
    watchLater,
    subscribeDrama,
    fetchUserDetail,
    fetchWaitchHistoryItems,
    fetchWaitchLaterItems,
    fetchDramas,
    fetchSingleDrama,
    fetchEpisodes,
    fetchEpisodeDetail,
    comment,
    fetchComments,
    fetchSkuInfo,
    createInnerOrder,
    buyEpisode,
    checkOrder,
    addShareCredits,
    setError,
  };
};

export default ViewModel;
