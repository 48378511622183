import React, { useEffect, useState, useRef } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Box, Popover, Paper, InputBase, Avatar, Button, Modal, Divider, Skeleton, Grid2, Card, CardContent, RadioGroup, FormControlLabel, Radio, Input, useMediaQuery } from '@mui/material';
import { Login, History, WatchLater, AccountCircle, Search, Paid, Logout, ShoppingCart, Info, Lightbulb, Menu,HourglassTop } from '@mui/icons-material';
import ContentList from './ContentList';
import { GoogleLogin } from '@react-oauth/google';
import { SubscribeAnima } from './Animation'
import Cookies from 'js-cookie';
import { useDrama } from '../contexts/DramaContext';
import { LocalStorageContext } from '../utils/localStorage'
import { ReactComponent as PaypalIcon } from '../assets/svg/paypal.svg'
import PayPalPayment from './Payment';
import avatarImage from '../assets/images/avatar.png';
import { format, parseISO } from 'date-fns';
import ReactGA from 'react-ga4';


const AppBarComponent = ({ viewModel }) => {
  const {
    fetchWaitchHistoryItems,
    subscribeDrama,
    fetchUserDetail,
    fetchDramas,
    fetchSkuInfo,
    signWithGoogleSuccess
  } = viewModel;

  const [anchorEl, setAnchorEl] = useState(null);
  const [watchLaterList, setWatchLaterList] = useState(null);
  const [watchHistoryList, setWatchHistoryList] = useState(null);
  const [searchResults, setSearchResults] = useState(false);
  const [skuInfo, setSkuInfo] = useState(false);

  const [popoverContent, setPopoverContent] = useState('');
  // 设置默认选中的值为第二个卡片
  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState(null);

  const [searchQuery, setSearchQuery] = useState(""); // 新增状态来存储搜索框的输入值

  const [pointAmount, setPointAmount] = useState(10);
  const [pointAmountCost, setPointAmountCost] = useState(0.00);

  const [showPaypal, setShowPaypal] = useState(false);

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const searchInputRef = useRef(null);
  const userPopupRef = useRef(null);

  const { showPaymentDialog, setShowPaymentDialog,
    setCurrentDramaId, toggleDrawer,
    userDetail, setUserDetail,
    setUserSubscribe,
    signInModalOpen,setSignInModalOpen } = useDrama();
  // 使用useMediaQuery来检测屏幕宽度
  const matches = useMediaQuery('(max-width:600px)');


  const handlePaymentSuccess = (details) => {
    // 添加GA跟踪
    ReactGA.event({
      category: 'Payment',
      action: 'Payment Success',
      label: `Payment completed: ${selectedPaymentPlan}`
    });
    
    console.log('支付成功回调', details);
    setShowPaypal(false)
    setShowPaymentDialog(false)
      (async () => {
        const res = await fetchUserDetail()
        setUserDetail(res)
      })();
    const fakeEvent = { currentTarget: userPopupRef.current };
    handlePopoverOpen(fakeEvent, 'User')
    // 在这里处理支付成功后的逻辑
  }; // 添加依赖项，如果有依赖可以在这里指定

  const handlePaymentFailure = (err) => {
    console.error('支付失败回调', err);
    // 在这里处理支付失败后的逻辑
  }; // 添加依赖项，如果有依赖可以在这里指定

  const handlePointAmountChange = (event) => {
    let amount = event.target.value;
    if (amount < 0) {
      amount = 1
    } else if (amount > 999) {
      amount = 999
    }
    setPointAmount(amount);
  };

  function handleBlur(event) {
    let amount = event.target.value;
    if (amount < 0) {
      amount = 1
    } else if (amount > 999) {
      amount = 999
    }
    setPointAmount(amount);
  }

  const handleSelectedPaymentPlanChange = (event) => {
    setSelectedPaymentPlan(event.target.value);
  };

  const userPopoverOpen = async (currentTarget) => {
    const sessionId = Cookies.get('sessionId');
    if (!sessionId) {
      setSignInModalOpen(true)
      return
    }
    // 添加GA跟踪
    ReactGA.event({
      category: 'Navigation',
      action: 'Open User Menu',
      label: 'User profile view'
    });
    const res = await fetchUserDetail();
    setUserDetail(res)
    setAnchorEl(currentTarget);
    setPopoverContent("User");
  }

  const watchHistoryPopoverOpen = async (currentTarget) => {
    // 添加GA跟踪
    ReactGA.event({
      category: 'Navigation',
      action: 'Open History',
      label: 'Watch history view'
    });
    
    const sessionId = Cookies.get('sessionId');
    if (sessionId) {
      const res = await fetchWaitchHistoryItems();
      console.log("watchHistoryList", res)
      setWatchHistoryList(res.filter(item => item.historyType === "WATCH_HISTORY"))
    } else {
      const item = LocalStorageContext.getItem("watchHistory")
      if (!item) {
        return
      }
      setWatchHistoryList(item)
    }
    setAnchorEl(currentTarget);
    setPopoverContent("History");
  }

  const watchLaterPopoverOpen = async (currentTarget) => {
    // 添加GA跟踪
    ReactGA.event({
      category: 'Navigation',
      action: 'Open Watch Later',
      label: 'Watch later list view'
    });
    
    const sessionId = Cookies.get('sessionId');
    if (sessionId) {
      const res = await fetchWaitchHistoryItems();
      console.log("watchLaterList", res)
      setWatchLaterList(res.filter(item => item.historyType === "WATCH_AFTER"))
    } else {
      const item = LocalStorageContext.getItem("watchLater")
      if (!item) {
        return
      }
      console.log("watchLaterList", item)
      setWatchLaterList(item)
    }
    setAnchorEl(currentTarget);
    setPopoverContent("Later");
  }

  const handlePopoverOpen = async (event, content) => {
    const currentTarget = event.currentTarget;
    if (content === 'User') {
      userPopoverOpen(currentTarget)
    } else if (content === 'History') {
      watchHistoryPopoverOpen(currentTarget);
    } else if (content === 'Later') {
      watchLaterPopoverOpen(currentTarget)
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent('');
  };

  const handleLoginSuccess = (response) => {
    // 添加GA跟踪
    ReactGA.event({
      category: 'User',
      action: 'Login',
      label: 'Google login success'
    });

    (async () => {
      const res = await signWithGoogleSuccess(response.credential)
      if (res && res.sessionId) {
        // 保存 session 到 cookie，设置有效期为 30 天
        Cookies.set('sessionId', res.sessionId, { expires: 30 });
        setSignInModalOpen(false)
      }
    })();
  };

  const handleLoginFailure = (response) => {
    console.log('Login Failed:', response);
  };

  const logout = () => {
    // 添加GA跟踪
    ReactGA.event({
      category: 'User',
      action: 'Logout',
      label: 'User logged out'
    });
    
    Cookies.remove('sessionId')
    setUserDetail(null)
    handlePopoverClose()
  };

  useEffect(() => {
    (async () => {
      const res = await fetchUserDetail()
      setUserDetail(res)
    })();
  }, [])


  useEffect(() => {
    if (!skuInfo || skuInfo.length <= 0) {
      return
    }
    for (let i = 0; i < skuInfo.length; i++) {
      const sku = skuInfo[i]
      if(sku.skuId === '3'){
        setPointAmountCost(pointAmount * sku.amount/100)
      }
    }
  }, [skuInfo,pointAmount])

  useEffect(() => {
    if (showPaymentDialog) {
      (async () => {
        const res = await fetchSkuInfo()
        
        setSkuInfo(res)
        if (Array.isArray(res) && res.length > 0) {
          setSelectedPaymentPlan(res[0].skuId);
        }
      })();
    }
  }, [showPaymentDialog])

  const credit = async () => {
    // 添加GA跟踪
    ReactGA.event({
      category: 'Payment',
      action: 'Open Credit Dialog',
      label: 'Credit purchase view'
    });
    
    const sessionId = Cookies.get('sessionId');
    if (!sessionId) {
      setSignInModalOpen(true);
      return
    }
    const res = await fetchSkuInfo()
    setSkuInfo(res)
    if (Array.isArray(res) && res.length > 0) {
      setSelectedPaymentPlan(res[0].skuId);
    }
    handlePopoverClose()
    setShowPaymentDialog(true)
  }

  const subscribe = async () => {
    if (!searchQuery) {
      return;
    }
    // 添加GA跟踪
    ReactGA.event({
      category: 'Subscription',
      action: 'Subscribe',
      label: `Subscribed to: ${searchQuery}`
    });
    
    await subscribeDrama(searchQuery)
    const res = await fetchWaitchHistoryItems()
    const sb = res.filter(item => item.historyType === "SUBSCRIBE")
    if (!sb || sb.length <= 0) {
      return
    }
    setUserSubscribe(sb)
  }

  // 定义执行搜索的函数
  const performSearch = async (event) => {
    if (!searchQuery) {
      return;
    }
    // 添加GA跟踪
    ReactGA.event({
      category: 'Search',
      action: 'Perform Search',
      label: `Search query: ${searchQuery}`
    });
    
    console.log("searchQuery", searchQuery)
    const res = await fetchDramas(1, 10, searchQuery);
    setSearchResults(res);
    if (event && event.currentTarget) {
      setAnchorEl(event.currentTarget);
    } else if (searchInputRef.current) {
      setAnchorEl(searchInputRef.current);
    }
    setPopoverContent('Search');
  };


  const watchSelect = (item) => {
    setCurrentDramaId(item.dramaId)
  }

  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: "black",
      },
      children: `${name[0]}`,
    };
  }

  return (
    <>
      <AppBar position='relative' sx={{ background: "linear-gradient(to bottom, #0f0f0f, #1c1c1c)" }}>
        <Toolbar>
          {matches && <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
            sx={{ mr: 2, padding: 0 }}>
            <Menu />
          </IconButton>}

          <Typography variant={matches ? 'h6' : 'h5'} sx={{
            flexGrow: 1,
            fontStyle: 'italic',
            fontFamily: 'Monospace',
            letterSpacing: matches ? 1 : 2,
            fontWeight: 'bold',
            background: 'linear-gradient(to bottom, #ffffff, #da70d6 ,#800080)', // 使用选择的渐变色
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}>BingeShorts</Typography>

          {matches ? (
            <IconButton
              ref={searchInputRef}
              color="inherit"
              aria-label="search"
              onClick={() => setIsSearchOpen(true)}
            >
              <Search />
            </IconButton>
          ) : (
            <Paper>
              <InputBase
                sx={{
                  width: 'auto',
                  minWidth: 220,
                  paddingLeft: 1,
                  flex: 1,
                  color: 'black',
                }}
                placeholder="Search Drama You Want See"
                inputProps={{ 'aria-label': 'Search Drama You Want See' }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    performSearch(e);
                  }
                }}
              />
              <IconButton
                ref={searchInputRef}
                type="button"
                sx={{ color: 'black' }}
                aria-label="search"
                onClick={(e) => performSearch(e)}
              >
                <Search size='medium' />
              </IconButton>
            </Paper>
          )}


          {['History', 'Later', 'User'].map((content, index) => (
            <IconButton ref={index === 2 ? userPopupRef : null} key={content} color="inherit" size={matches ? 'medium' : 'large'} onClick={(e) => handlePopoverOpen(e, content)}>
              {index === 0 ? <History /> :
                index === 1 ? <WatchLater /> :
                  userDetail && userDetail.email ? <Avatar src={avatarImage} /> : <AccountCircle />}
            </IconButton>
          ))}
        </Toolbar>
      </AppBar>

      <Popover
        open={Boolean(anchorEl) && popoverContent === 'History'}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Paper>
          <ContentList title="Watch History" items={watchHistoryList} onClick={watchSelect} />
        </Paper>
      </Popover>

      <Popover
        open={Boolean(anchorEl) && popoverContent === 'Later'}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Paper>
          <ContentList title="Watch Later" items={watchLaterList} onClick={watchSelect} />
        </Paper>
      </Popover>

      <Popover
        open={Boolean(anchorEl) && popoverContent === 'Search'}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Paper>
          {searchResults && searchResults.total > 0 && (
            <>
              <ContentList title="Search Result" items={searchResults.data} onClick={watchSelect} />
              <Divider />
            </>
          )}
          <Box sx={{ p: 2, textAlign: 'center' }}>
            <Typography>Couldn't find the result you wanted? Subscribe to us, and we'll notify you when there's an update.</Typography>
            <Box><SubscribeAnima onClick={subscribe} /></Box>
          </Box>
        </Paper>
      </Popover>

      <Popover
        open={Boolean(anchorEl) && popoverContent === 'User'}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Paper sx={{ width: matches ? '90vw' : '20vw', padding: 0.5 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 1 }}>
            {userDetail && userDetail.email ? (<Avatar sx={{ width: 32, height: 32 }} {...stringAvatar(userDetail.email)} />) : (<Skeleton variant="circular">
              <Avatar />
            </Skeleton>)}
            <Typography variant="body2" sx={{ marginTop: 1 }}>{userDetail && userDetail.email ? userDetail.email : <Skeleton />}</Typography>
          </Box>
          <Divider />
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2, justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Paid fontSize='medium' sx={{ color: 'primary.light' }} />
              <Typography sx={{ fontWeight: 'bold',fontSize: 18 }}>: {userDetail && userDetail.scoreCount ? userDetail.scoreCount : 0}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1, justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <HourglassTop fontSize='medium' sx={{ color: 'secondary.light' }} />
              <Typography sx={{ fontWeight: 'bold',fontSize: 18  }}>: {userDetail && userDetail.discountExpireTime ? format(parseISO(userDetail.discountExpireTime), 'yyyy-MM-dd HH:mm') : ""}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 1 }}>
            <IconButton color="inherit"  onClick={credit}>
              <ShoppingCart fontSize='small' sx={{ color: 'primary.dark' }}/>
            </IconButton>
            <IconButton color="inherit"  onClick={logout}>
              <Logout fontSize='small' sx={{ color: 'primary.dark' }}/>
            </IconButton>
          </Box>
        </Paper>
      </Popover>
      <Modal
        open={signInModalOpen}
        onClose={() => setSignInModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 12,
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: 300,
        }}>
          <Card>
            <IconButton size='large' color='primary' sx={{ padding: 1 }}>
              <Login />
            </IconButton>
          </Card>
          <Typography id="modal-modal-title" variant="h5" align="center" sx={{ fontWeight: 'bold', marginTop: 1 }}>
            Sign In
          </Typography>
          <Typography id="modal-modal-title" variant="caption" align="center" sx={{ color: 'gray', marginTop: 0.5 }}>
            Log in to unlock a treasure trove of free episodes!
          </Typography>
          <Box id="modal-modal-description" sx={{ marginTop: 3 }}>
            <GoogleLogin
             locale='en'
              mobile={true}
              onSuccess={handleLoginSuccess}
              onFailure={handleLoginFailure}
            />
          </Box>

        </Box>
      </Modal>


      <Modal
        open={showPaymentDialog}
        onClose={() => setShowPaymentDialog(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: matches ? '90vw' : '60vw' ,
          height: 'auto',
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          borderRadius: 3,
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflowY: 'scroll',
        }}>
          <Box>
            <Typography variant={matches ? "h6" : "h3"} align="center" sx={{ fontWeight: "bold" }} gutterBottom>
              Payment Plans
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', marginBottom: 1 }}>
              {matches ? null : <Lightbulb size='small' sx={{ color: 'primary.main', marginRight: 1 }} />}
              <Typography variant={matches ? "caption" : "body2"} align="center" sx={{color:'rgba(0,0,0,0.7)'}} >
                Watching any episode consumes 1 point, and each point costs $0.07. For the price of just half a movie ticket, you can unlock a drama.
              </Typography>
            </Box>
            <Divider sx={{ mt: 2, mb: 4 }} />
            <RadioGroup value={selectedPaymentPlan} onChange={handleSelectedPaymentPlanChange}>
              <Grid2 container spacing={1} justifyContent="center">
                {Array.isArray(skuInfo) && skuInfo.map((item, index) => (
                  <Grid2 size={{ xs: 12, md: 4 }} key={index}>
                    <Card sx={{ width: '100%', height: '100%', position: 'relative' }}>
                      <FormControlLabel
                        value={index}
                        control={<Radio sx={{
                          padding: 0,
                          color: 'primary.main',
                          '&.Mui-checked': {
                            color: 'secondary.main',
                          },
                        }} />}
                        sx={{ position: 'absolute', top: 1, right: 0 }}
                      />
                      <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <Typography variant={matches ? "subtitle1" : "h6"} align="center" sx={{ fontWeight: 'bold' }} gutterBottom>
                          {item.skuName}
                        </Typography>
                        <Typography variant={matches ? "body2" : "subtitle2"} align="center" gutterBottom sx={{
                          maxWidth: '90%',
                          whiteSpace: 'normal',
                          textOverflow: 'ellipsis',
                          wordWrap: 'break-word',
                          display: '-webkit-box',
                          WebkitLineClamp: 4,
                          WebkitBoxOrient: 'vertical',
                          textAlign: 'center',
                          margin: '0 auto',
                          mb:1,
                          flexGrow: 1,
                          overflow:"scroll"
                        }}>
                          {item.skuDesc}
                        </Typography>
                        <Typography variant={matches ? "h6" : "h3"} align="center" sx={{ fontWeight: 'bold',mb:3 }} >
                          {item.skuId === '3' ? pointAmountCost : item.amount/100}$
                        </Typography>
                        {item.skuId === "3" && (
                          <Box  sx={{ 
                            display: 'flex', 
                            position: 'absolute', 
                            bottom: 10, 
                            left: '50%', 
                            transform: 'translateX(-50%)',
                            width: 'fit-content'  // 确保 Box 宽度适应内容
                          }}>
                            <Input
                              value={pointAmount}
                              size="small"
                              onChange={(e) => {handlePointAmountChange(e)}}
                              onBlur={handleBlur}
                              inputProps={{
                                step: 1,
                                min: 1,
                                max: 999,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                              }}
                              sx={{
                                '& .MuiInputBase-input': {
                                  textAlign: 'center', // 文本居中对齐
                                  paddingLeft: '4px', // 移除左内边距
                                  paddingRight: '4px', // 移除右内边距
                                  color: 'secondary.main',
                                  fontWeight: 'bold'
                                },
                              }}
                            />
                          </Box>)
                        }
                      </CardContent>

                    </Card>
                  </Grid2>
                ))}
              </Grid2>
            </RadioGroup>

            <Divider sx={{ mt: 4, mb: 2 }} />

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 6 }}>
              {matches ? null : <Info size='small' sx={{ color: 'secondary.main', marginRight: 1 }} />}
              <Typography variant={matches ? "caption" : "body2"} align="center" sx={{color:'rgba(0,0,0,0.7)'}}>
                100% secure payment method with money back guarantee.
              </Typography>
            </Box>

            <Box sx={{
              position: 'absolute',
              bottom: 8,
              right: 8,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}>
              <Button
                variant="contained"
                sx={{
                  fontWeight: 'bold',
                }}
                onClick={() => setShowPaypal(true)}
                startIcon={<PaypalIcon style={{ width: 30, height: 30 }} />}
              >
                Pay Now
              </Button>
            </Box>

          </Box>
        </Box>
      </Modal>

      <Modal
        open={showPaypal}
        onClose={() => setShowPaypal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '30vh',
          height: 'auto',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          borderRadius: 3,
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}>
          <PayPalPayment
            viewModel={viewModel}
            skuInfo={skuInfo[selectedPaymentPlan]}
            pointAmount={pointAmount}
            onSuccess={handlePaymentSuccess}
            onFailure={handlePaymentFailure}
          />
        </Box>
      </Modal>
      {/* 手机端搜索框弹出层 */}
      <Modal
        open={isSearchOpen}
        onClose={() => setIsSearchOpen(false)}
        aria-labelledby="search-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '10%',
          left: '50%',
          transform: 'translateX(-50%)',
          width: '90%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2,
        }}>
          <InputBase
            autoFocus
            fullWidth
            placeholder="Search Drama You Want See"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                performSearch();
                setIsSearchOpen(false);
              }
            }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default AppBarComponent;
