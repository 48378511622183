import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Fab, Button, IconButton, useMediaQuery } from '@mui/material';
import { ChevronRight, PlayArrow } from '@mui/icons-material';
import { useDrama } from '../contexts/DramaContext';
import styled from '@emotion/styled';
import Grid from '@mui/material/Grid2';
import ReactGA from 'react-ga4';


const FloatingButtons = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: 2,
    top: '50%',
    transform: 'translateY(-50%)',
    gap: '16px',
});

const ScrollContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll', // 取消滑动交互
    scrollBehavior: 'smooth', // 滑动行为设为平滑
    width: '100%',
    maxHeight: '100%',
    '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
        display: 'none'
    },
    '&::-webkit-scrollbar-track': {
        display: 'none'
    },
    '&::-webkit-scrollbar-thumb': {
        display: 'none'
    },
    msOverflowStyle: 'none',  // IE和Edge
    scrollbarWidth: 'none',  // Firefox
});

const ListSection = (props) => {

    // 使用useMediaQuery来检测屏幕宽度
    const matches = useMediaQuery('(max-width:600px)');
    const { listData } = props;

    const scrollRef = useRef(null)

    const handleScroll = () => {
        // 添加GA跟踪
        ReactGA.event({
            category: 'Navigation',
            action: 'Horizontal Scroll',
            label: 'Scrolled video list'
        });

        if (scrollRef.current) {
            const container = scrollRef.current;
            // 每次点击增加的滑动距离
            const scrollIncrement = 300;
            // 计算新的滑动位置
            const newScrollPosition = container.scrollLeft + scrollIncrement;
            // 设置新的滑动位置
            container.scrollLeft = newScrollPosition;
        }
    };

    const { setCurrentDramaId, setSelectedItem, setDiableFetchDrama } = useDrama();

    const handleWatchNow = (id) => {
        // 添加GA跟踪
        ReactGA.event({
            category: 'Video',
            action: 'Play from ShowContent List',
            label: `Started video: ${id}`
        });

        setCurrentDramaId(id)
        setDiableFetchDrama(true)
        setSelectedItem('Hot')
    }

    return (
        <Box sx={{ display: 'flex', position: 'relative', overflow: 'hidden' }}>
            <ScrollContainer ref={scrollRef}>
                {listData.map((item, index) => (
                    <Box key={index} sx={{ flex: 'none', width: matches ? '46%' : '10%', height: '100%', marginRight: index !== listData.length - 1 ? 2 : 0 }}>
                        <Card sx={{ width: '100%', height: '100%', backgroundColor: '#1c1c1c', position: 'relative' }}>
                            <CardMedia component="picture" sx={{
                                width: '100%',
                                height: '90%',
                            }}>
                                <img src={item.imgUrl} style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover'
                                }} alt={item.name}></img>
                            </CardMedia>
                            <CardContent sx={{
                                height: '10%', padding: 0, display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <Typography variant="caption" sx={{
                                    textAlign: 'left',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: 'vertical',
                                    color: 'white',
                                    margin: '0 auto'
                                }}>{item.name}
                                </Typography>
                            </CardContent>
                            <Box sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <IconButton sx={{
                                    width: '60px',
                                    height: '60px',
                                    color: 'white',
                                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.4)'
                                    }
                                }}
                                    href={`/drama/${item.dramaId}`}
                                    onClick={() => handleWatchNow(item.dramaId)}>
                                    <PlayArrow sx={{ fontSize: 48 }} />
                                </IconButton>
                            </Box>
                        </Card>
                    </Box>
                ))}
            </ScrollContainer>
            <FloatingButtons>
                <Fab color="primary" aria-label="like" onClick={handleScroll}><ChevronRight /></Fab>
            </FloatingButtons>
        </Box>)

};

const ShowContent = ({ viewModel }) => {

    // 使用useMediaQuery来检测屏幕宽度
    const matches = useMediaQuery('(max-width:600px)');

    const {
        fetchDramas,
    } = viewModel;


    const [listData, setListData] = useState({});
    const [previewData, setPreviewData] = useState({});
    const { setCurrentDramaId, setSelectedItem, setDiableFetchDrama } = useDrama();

    useEffect(() => {
        (async () => {
            const results = await fetchDramas(1, 20);
            if (results && results.data && results.data.length > 0) {
                let preview = {
                    large: results.data[0] || {},
                    small: results.data.slice(1, 3)
                };
                setPreviewData(preview);
                setListData(results.data.slice(3));
            }
        })();
    }, []);

    const handleWatchNow = (id) => {
        // 添加GA跟踪
        ReactGA.event({
            category: 'Video',
            action: 'Play from ShowContent Featured',
            label: `Started video: ${id}`
        });

        setCurrentDramaId(id)
        setDiableFetchDrama(true)
        setSelectedItem('Hot')
    }

    return (
        <Box sx={{ display: 'flex', height: '100%', width: '100%', flexDirection: 'column', padding: 2, overflow: 'hidden' }}>
            {previewData && previewData.large && Object.keys(previewData.large).length > 0 && (
                <Grid container spacing={1} >
                    <Grid size={{ xs: 12, md: 7 }}>
                        <Card sx={{ display: 'flex', backgroundColor: '#1c1c1c', position: 'relative', height: '100%', overflow: 'hidden' }}>
                            <CardMedia component="picture" sx={{
                                width: '30%',
                                height: '100%'
                            }}>

                                <img src={previewData.large.imgUrl} alt={previewData.large.name} style={{
                                    width: '100%',
                                    objectFit: 'contain',
                                    objectPosition: 'center'
                                }}></img>

                            </CardMedia>

                            <CardContent sx={{ flex: '1', flexDirection: 'column', alignItems: 'flex-start', color: 'white', width: '70%' }}>
                                <Typography variant={matches ? 'h6' : 'h5'} sx={{ textAlign: 'left' }}>{previewData.large.name}</Typography>
                                <Typography variant="caption" sx={{ textAlign: 'left' }}>{previewData.large.dramaDetailCount} Episodes</Typography>
                                <Typography variant="body1" sx={{
                                    textAlign: 'left',
                                    overflow: 'scroll',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: matches ? 2 : 7,
                                    maxHeight: '60%',
                                    WebkitBoxOrient: 'vertical',
                                }}>{previewData.large.desc}</Typography>
                            </CardContent>
                            {
                                matches ? (<Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                                    <IconButton size='small' sx={{ color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
                                        value={previewData.large.dramaId}
                                        href={`/drama/${previewData.large.dramaId}`}
                                        onClick={(e) => handleWatchNow(e.target.value)}
                                    >
                                        <PlayArrow fontSize="small" />
                                    </IconButton>
                                </Box>) : (<Box sx={{ position: 'absolute', bottom: 12, right: 12 }}>
                                    <Button variant="outlined" size='large' startIcon={<PlayArrow />}
                                        value={previewData.large.dramaId}
                                        href={`/drama/${previewData.large.dramaId}`}
                                        onClick={(e) => handleWatchNow(e.target.value)}
                                    >
                                        Watch Now
                                    </Button>
                                </Box>)
                            }
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 12, md: 5 }} container spacing={1}>
                        {previewData.small.map((item, index) => (
                            <Grid key={index} size={{ xs: 6, md: 12 }}>
                                <Card sx={{ display: 'flex', backgroundColor: '#1c1c1c', position: 'relative', height: matches ? 100 : '100%' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <CardMedia component="picture" sx={{
                                            width: matches ? '68px' : '22%',
                                            height: '100%'
                                        }} >
                                            <img
                                                src={item.imgUrl}
                                                alt={item.name}
                                                style={{
                                                    width: matches ? '68px' : '100%',
                                                    objectFit: 'cover',
                                                    objectPosition: 'center'
                                                }}
                                            />
                                        </CardMedia>

                                        <CardContent sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            height: '100%',
                                            width: '70%',
                                            padding: 0,
                                            marginLeft: 1,
                                            color: 'white'
                                        }}>
                                            <Typography variant={matches ? 'body2' : 'h6'} sx={{
                                                textAlign: 'left',
                                                overflow: 'scroll',
                                                textOverflow: 'ellipsis',
                                                display: '-webkit-box',
                                                WebkitLineClamp: 2,
                                                WebkitBoxOrient: 'vertical'
                                            }}>{item.name}</Typography>
                                            <Typography variant="caption" sx={{ textAlign: 'left', marginTop: matches ? 1 : 0 }}>{item.dramaDetailCount} Episodes</Typography>
                                            {matches ? null : <Typography variant="body1" sx={{
                                                textAlign: 'left',
                                                overflow: 'scroll',
                                                textOverflow: 'ellipsis',
                                                display: '-webkit-box',
                                                WebkitLineClamp: 3,
                                                maxHeight: '60%',
                                                WebkitBoxOrient: 'vertical'
                                            }}>{item.desc}</Typography>}
                                        </CardContent>

                                        {
                                            matches ? (<Box sx={{ position: 'absolute', bottom: 4, right: 4 }}>
                                                <IconButton size='small' sx={{ color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
                                                    value={item.dramaId}
                                                    href={`/drama/${item.dramaId}`}
                                                    onClick={(e) => handleWatchNow(e.target.value)}
                                                >
                                                    <PlayArrow fontSize="small" />
                                                </IconButton>
                                            </Box>) : (<Box sx={{ position: 'absolute', bottom: 12, right: 12 }}>
                                                <Button variant="outlined" size='small' startIcon={<PlayArrow />}
                                                    value={item.dramaId}
                                                    href={`/drama/${item.dramaId}`}
                                                    onClick={(e) => handleWatchNow(e.target.value)}
                                                >
                                                    Watch Now
                                                </Button>
                                            </Box>)
                                        }
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            )}

            <Typography variant="h4" gutterBottom sx={{ color: 'white', mt: 2 }}>
                Must-sees
            </Typography>
            {listData && listData.length > 0 && <ListSection listData={listData} />}
        </Box>
    );
};

export default ShowContent;