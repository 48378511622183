import React, { useRef,useEffect} from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Fab ,useMediaQuery} from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import styled from '@emotion/styled';
import subscribeImage from '../assets/images/subscribe.jpeg';
import { useDrama } from '../contexts/DramaContext';

const FloatingButtons = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    gap: '16px',
});

const ScrollContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll', // 取消滑动交互
    scrollBehavior: 'smooth', // 滑动行为设为平滑
    width: '100%',
    maxHeight: '100%',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    msOverflowStyle: 'none',  // IE和Edge
    scrollbarWidth: 'none',  // Firefox
});

const SubscriptionsContent = ({ viewModel }) => {

    // 使用useMediaQuery来检测屏幕宽度
    const matches = useMediaQuery('(max-width:600px)');

    const {
        fetchWaitchHistoryItems
      } = viewModel;


    const {userSubscribe ,setUserSubscribe} = useDrama()

      useEffect(() => {
        (async () => {
            const res = await fetchWaitchHistoryItems()
            const sb = res.filter(item => item.historyType === "SUBSCRIBE")
            if(!sb || sb.length <= 0){
                return
            }
            setUserSubscribe(sb)
        })()
      }, []);

    const scrollRef = useRef(null)

    const handleScroll = () => {
        if (scrollRef.current) {
            const container = scrollRef.current;
            // 每次点击增加的滑动距离
            const scrollIncrement = 300;
            // 计算新的滑动位置
            const newScrollPosition = container.scrollLeft + scrollIncrement;
            // 设置新的滑动位置
            container.scrollLeft = newScrollPosition;
        }
    }


    return (<Box sx={{ display: 'flex', height: '100%', width: '100%', flexDirection: 'column', padding: 2, overflow: 'hidden' }}>
        <Typography variant="h4" gutterBottom sx={{ color: 'white',fontWeight:'bold'}}>Subscriptions</Typography>
        <Box sx={{ display: 'flex', position: 'relative', overflow: 'hidden' }}>
            <ScrollContainer ref={scrollRef}>
              
                {userSubscribe && userSubscribe.map((item, index) => (
                    <Box key={index} sx={{ flex: 'none', width: matches ? '46%' : '12%', height: '100%', marginLeft:1 }}>
                        <Card sx={{ width: '100%', height: '100%', backgroundColor: '#1c1c1c', position: 'relative' }}>
                            <CardMedia component="img" sx={{
                                width: '100%',
                                height: '90%',
                                objectFit: 'cover'
                            }} image={item && item.imgUrl ? item.imgUrl : subscribeImage} />
                            <CardContent sx={{
                                width: '100%',
                                height: '10%', 
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <Typography variant="body2" sx={{
                                    textAlign: 'left',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: 'vertical',
                                    color: 'white',
                                    fontWeight:'bold',
                                    margin: '0 auto'
                                }}>{item.dramaName}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                    
                ))}
            </ScrollContainer>
            <FloatingButtons>
                <Fab color="primary" aria-label="like" onClick={handleScroll}><ChevronRight /></Fab>
            </FloatingButtons>
        </Box>
    </Box>);
};

export default SubscriptionsContent;