import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuList, MenuItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import { LocalFireDepartment, Update, Subscriptions,Category,ContactMail } from '@mui/icons-material';
import { useDrama } from '../contexts/DramaContext';
import ReactGA from 'react-ga4';

const LeftSidebar = () => {

  const navigate = useNavigate();

  const { selectedItem, setSelectedItem,toggleDrawer} = useDrama();

  const menuItems = [
    { icon: <LocalFireDepartment />, label: 'Hot', path: '/drama' },
    { icon: <Update />, label: 'Updates', path: '/updates' },
    { icon: <Category />, label: 'Category', path: '/category' },
    { icon: <Subscriptions />, label: 'Subscriptions', path: '/subscriptions' },
    { icon: <ContactMail />, label: 'Contact Us', path: '/contact' } 
  ];

  const handleMenuItemClick = (label,path) => {
    // 添加GA跟踪
    ReactGA.event({
      category: 'Navigation',
      action: 'Menu Click',
      label: `Clicked menu item: ${label}`
    });
    
    setSelectedItem(label);
    navigate(path);
    toggleDrawer();
  };

  return (
      <MenuList sx={{ padding: 0}}>
        {menuItems.map((item) => (
          <MenuItem
            key={item.label}
            onClick={() => handleMenuItemClick(item.label,item.path)}
            sx={{
              ml:1,
              pt:1,
              pl:0.5,
              pr:0.5,
              pb:1,
              backgroundColor: selectedItem === item.label ? '#2b2b2b' : 'transparent',
              '&:hover': {
                backgroundColor: '#2b2b2b',
              },
              borderRadius: 1,
            }}
          >
            <ListItemIcon>
              <IconButton size='large' sx={{color: 'white' }}>
                {React.cloneElement(item.icon)}
              </IconButton>
            </ListItemIcon>
            <ListItemText
              primary={item.label}
              primaryTypographyProps={{fontWeight: 'bold', color: 'white' }}
            />
          </MenuItem>
        ))}
      </MenuList>
  );
};

export default LeftSidebar;
