import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Box, Chip, Card, CardMedia, Typography, CardContent, IconButton, Drawer, useMediaQuery } from '@mui/material';
import { PlayArrow, KeyboardArrowDown, Close } from '@mui/icons-material';
import { Masonry } from '@mui/lab';
import styled from '@emotion/styled';
import { useDrama } from '../contexts/DramaContext';
import ReactGA from 'react-ga4';

const tags = ["All", "Ancient Style", "Fantasy", "Rebirth", "Superpower", "Bossy Hero", "Palace", "Love Story", "Modern Romance", "Flash Marriage", "Sweet Love", "Time Travel", "City Life", "Counterattack", "God of War", "Revenge", "Vintage Clothing", "Pure Love", "Daily Life", "For Girls", "For Boys", "For Elderly", "Funny", "Inspiring", "Positive Energy", "Sci-Fi Myth", "Youth Idol", "Military War", "Mystery Crime", "Fantasy Adventure", "Criminal Investigation", "Comedy", "Family", "Horror", "Republic of China Era", "Campus", "Love Skill", "Romance", "Gastronomy", "Miracle Doctor", "Passionate", "Cool Drama"]

const ScrollContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'scroll', // 取消滑动交互
    scrollBehavior: 'smooth', // 滑动行为设为平滑
    width: '100%',
    maxHeight: '100%',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    msOverflowStyle: 'none',  // IE和Edge
    scrollbarWidth: 'none',
    margin: 1
});


const TagScrollContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll', // 取消滑动交互
    scrollBehavior: 'smooth', // 滑动行为设为平滑
    width: '100%',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    msOverflowStyle: 'none',  // IE和Edge
    scrollbarWidth: 'none',  // Firefox
});

const CategoryContent = ({ viewModel }) => {

    // 使用useMediaQuery来检测屏幕宽度
    const matches = useMediaQuery('(max-width:600px)');
    const heights = [390, 410, 340, 360, 430];

    const [selectedTag, setSelectedTag] = useState(tags[0]);
    const [items, setItems] = useState([]);
    const {
        fetchDramas,
    } = viewModel;
    const [page, setPage] = useState(1);
    const [hitBottom, setHitBottom] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const lastElementRef = useRef(null);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    useEffect(() => {
        (async () => {
            const results = await fetchDramas(page, 20);
            if (results && results.data && results.data.length > 0) {
                setItems(results.data);
            }
        })();
    }, []);

    const handleSelectTag = async (tag) => {
        // 添加GA跟踪
        ReactGA.event({
            category: 'Category',
            action: 'Tag Select',
            label: `Selected tag: ${tag}`
        });

        setSelectedTag(tag);
        setDrawerOpen(false);
        setPage(1);
        setItems([]);
        setHitBottom(false);
        const reqtTag = tag === 'All' ? null : tag;
        const results = await fetchDramas(1, 20, null, reqtTag);
        console.log(results);
        if (results && results.data && results.data.length > 0) {
            setItems(results.data);
        }
    };

    const handleLoadMore = async () => {
        if (isLoading || hitBottom) return;
        setIsLoading(true);
        const nextPage = page + 1;
        const reqtTag = selectedTag === 'All' ? null : selectedTag;
        const results = await fetchDramas(nextPage, 20, null, reqtTag);
        if (results && results.data && results.data.length > 0) {
            setItems(prevItems => [...prevItems, ...results.data]);
            setPage(nextPage);
        } else {
            setHitBottom(true);
        }
        setIsLoading(false);
    };

    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
            handleLoadMore();
        }
    }, [handleLoadMore]);

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: '20px',
            threshold: 0
        };
        const observer = new IntersectionObserver(handleObserver, option);
        const currentRef = lastElementRef.current; // 在这
        if (currentRef) observer.observe(currentRef);
        return () => {
            if (currentRef) observer.unobserve(currentRef);
        };
    }, [handleObserver]);

    const { setCurrentDramaId, setSelectedItem, setDiableFetchDrama } = useDrama();

    const handleWatchNow = (id) => {
        // 添加GA跟踪
        ReactGA.event({
            category: 'Video',
            action: 'Play from Category',
            label: `Started video: ${id}`
        });

        setCurrentDramaId(id)
        setDiableFetchDrama(true)
        setSelectedItem('Hot')
    }

    return (
        <Box sx={{ display: 'flex', height: '100%', width: '100%', flexDirection: 'column', padding: 2, overflow: 'hidden' }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 3 }}>
                {matches ? (
                    <><Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        overflow: 'hidden'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexWrap: 'nowrap',
                            overflow: 'hidden',
                            width: '90%'
                        }}>
                            {tags.map((tag, index) => (
                                <Chip
                                    key={`${tag}-${index}`}
                                    label={tag}
                                    onClick={() => handleSelectTag(tag)}
                                    size='large'
                                    color={selectedTag === tag ? 'primary' : 'secondary'}
                                    sx={{ flexShrink: 0, mr: 1 }}
                                />
                            ))}
                        </Box>
                        <IconButton
                            size="large"
                            edge="end"
                            color="white"
                            aria-label="menu"
                            onClick={() => toggleDrawer(true)}
                            sx={{ ml: 'auto', color: 'white' }}
                        >
                            {drawerOpen ? <Close /> : <KeyboardArrowDown />}
                        </IconButton>
                    </Box>
                        <Drawer
                            anchor='bottom'
                            open={drawerOpen}
                            onClose={toggleDrawer}
                            sx={{
                                flexShrink: 0,
                                '& .MuiDrawer-paper': {
                                    backgroundColor: "#1c1c1c"
                                },
                            }}>
                            <Box sx={{ padding: 2 }}>
                                {tags.map((tag, index) => (
                                    <Chip
                                        key={`${tag}-${index}`}
                                        label={tag}
                                        onClick={() => handleSelectTag(tag)}
                                        size='large'
                                        color={selectedTag === tag ? 'primary' : 'secondary'}
                                        sx={{ margin: '5px' }}
                                    />
                                ))}
                            </Box>
                        </Drawer>
                    </>
                ) : (
                    tags.map((tag, index) => (
                        <Chip
                            key={`${tag}-${index}`}
                            label={tag}
                            onClick={() => handleSelectTag(tag)}
                            size='large'
                            color={selectedTag === tag ? 'primary' : 'secondary'}
                        />
                    ))
                )}
            </Box>
            <ScrollContainer>
                <Masonry columns={{ xs: 2, sm: 3, md: 4, lg: 5, xl: 6 }} spacing={2}>
                    {items.map((item, index) => {
                        const height = heights[index % heights.length];
                        return (<Box key={index} sx={{ height: height }}>
                            <Card sx={{ width: '100%', height: '100%', backgroundColor: '#1c1c1c', position: 'relative' }}>
                                <CardMedia component="picture" sx={{
                                    width: '100%',
                                    height: '82%',
                                }} >
                                    <img src={item.imgUrl} alt={item.name} style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover'
                                    }}></img>
                                </CardMedia>
                                <CardContent sx={{
                                    height: '18%',
                                    padding: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    justifyContent: 'flex-start'
                                }}>
                                    <Typography variant="caption" sx={{
                                        textAlign: 'left',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 1,
                                        WebkitBoxOrient: 'vertical',
                                        color: 'white',
                                        width: '100%'
                                    }}>{item.name}
                                    </Typography>
                                    {item && item.tagList && item.tagList.length > 0 && (
                                        <TagScrollContainer>
                                            {item.tagList.map((tag, index) => (
                                                <Chip
                                                    key={`${tag}-${index}`}
                                                    label={tag}
                                                    size='small'
                                                    clickable
                                                    sx={{ fontSize: 8, marginRight: 1, color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
                                                />
                                            ))}
                                        </TagScrollContainer>
                                    )}
                                </CardContent>
                                <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                                    <IconButton size='small' sx={{ color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
                                        value={item.dramaId}
                                        href={`/drama/${item.dramaId}`}
                                        onClick={(e) => handleWatchNow(item.dramaId)}
                                    >
                                        <PlayArrow fontSize="small" />
                                    </IconButton>
                                </Box>
                            </Card>
                        </Box>
                        );
                    })}
                </Masonry>
                <Box
                    ref={lastElementRef}
                    sx={{
                        height: '50px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >
                    {hitBottom && (
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: 1 }}>
                            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                <Box sx={{ width: '60px', height: '1px', backgroundColor: 'rgba(255,255,255,0.4)' }} />
                            </Box>
                            <Typography variant="body1" color='rgba(255,255,255,0.4)' sx={{ padding: '0 10px' }}>
                                no more content
                            </Typography>
                            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
                                <Box sx={{ width: '60px', height: '1px', backgroundColor: 'rgba(255,255,255,0.4)' }} />
                            </Box>
                        </Box>
                    )}
                </Box>

            </ScrollContainer>
        </Box>
    );
};

export default CategoryContent;
