import React, { createContext, useState, useContext } from 'react';

const DramaContext = createContext();

export const DramaProvider = ({ children }) => {
    const [selectedItem, setSelectedItem] = useState('Hot');
    const [currentDramaId, setCurrentDramaId] = useState(null);
    const [showPaymentDialog, setShowPaymentDialog] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [dramas,setDramas] = useState([])
    const [currentEpisodes, setCurrentEpisodes] = useState({})
    const [currentEpisodeDetail, setCurrentEpisodeDetail] = useState([])
    const [currentPlayTime, setCurrentPlayTime] = useState(0);
    const [diableFetchDrama, setDiableFetchDrama] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [userDetail, setUserDetail] = useState(null)
    const [userSubscribe, setUserSubscribe] = useState(null)
    const [comments, setComments] = useState([])
    const [signInModalOpen, setSignInModalOpen] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0)
    const [videoSwipe, setVideoSwipe] = useState(true)
    const [swiperInstance, setSwiperInstance] = useState(null);


    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    return (
        <DramaContext.Provider value={{
            selectedItem,
            setSelectedItem,
            currentDramaId,
            setCurrentDramaId,
            showPaymentDialog,
            setShowPaymentDialog,
            currentPage,
            setCurrentPage,
            dramas,
            setDramas,
            currentEpisodes,
            setCurrentEpisodes,
            currentEpisodeDetail,
            setCurrentEpisodeDetail,
            currentPlayTime,
            setCurrentPlayTime,
            diableFetchDrama,
            setDiableFetchDrama,
            isDrawerOpen,
            toggleDrawer,
            userDetail,
            setUserDetail,
            userSubscribe,
            setUserSubscribe,
            comments,
            setComments,
            signInModalOpen,
            setSignInModalOpen,
            activeIndex, 
            setActiveIndex,
            videoSwipe,
            setVideoSwipe,
            swiperInstance, 
            setSwiperInstance
        }}>
            {children}
        </DramaContext.Provider>
    );
};

export const useDrama = () => useContext(DramaContext);