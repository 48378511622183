import React, { useEffect, useRef, useState,useMemo } from 'react';
import { Box, IconButton, Button, InputBase, Typography, Slider, Modal, Select, MenuItem, InputLabel, FormControl, useMediaQuery } from '@mui/material';
import { PlayArrow, Pause, Loop, Speed, VolumeUp, VolumeOff, PictureInPicture, Fullscreen, Subtitles } from '@mui/icons-material';
import { VideoBuffering } from './Animation';
import { LocalStorageContext } from '../utils/localStorage'
import Cookies from 'js-cookie';
import { useDrama } from '../contexts/DramaContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Keyboard } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';

const getFontSizes = (matches) => ({
  Small: matches ? 24 : 28,
  Medium: matches ? 28 : 32,
  Large: matches ? 32 : 48
});


const VideoPlayer = ({ viewModel, playEnd, loadMoreDrama, ratio = 9 / 16 }) => {

  // 使用useMediaQuery来检测屏幕宽度
  const matches = useMediaQuery('(max-width:600px)');
  const fontSizes = getFontSizes(matches);

  const containerRef = useRef(null);
  const videoRef = useRef(null);

  const { reportPlayRecord, comment, fetchComments } = viewModel
  const { setCurrentPlayTime, currentEpisodeDetail, setComments, dramas, activeIndex, setActiveIndex, videoSwipe, setVideoSwipe, setSwiperInstance } = useDrama();

  const currentEpisodeDetailRef = useRef()
  currentEpisodeDetailRef.current = currentEpisodeDetail

  const [subtitleLang, setSubtitleLang] = useState('en');
  const [videoSize, setVideoSize] = useState({ width: 0, height: 0 });
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [isLooping, setIsLooping] = useState(false);
  const [currentSubtitle, setCurrentSubtitle] = useState('');
  const [subtitleSettings, setSubtitleSettings] = useState({
    position: 'bottom',
    size: 'Medium',
    color: '#ffffff',
    backgroundColor: 'rgba(0, 0, 0, 0)'
  });
  const [showSubtitleSettings, setShowSubtitleSettings] = useState(false);
  const [parsedSubtitles, setParsedSubtitles] = useState([]);
  const [loadingStates, setLoadingStates] = useState({
    isBuffering: false,
    isSeeking: false,
    isInitialLoading: true
  });

  const updateLoadingState = (type, value) => {
    setLoadingStates(prev => ({
      ...prev,
      [type]: value
    }));
  };

  const isLoading = useMemo(() => {
    return Object.values(loadingStates).some(state => state);
  }, [loadingStates]);
  const [commentContent, setCommentContent] = useState(null);

  const reportIntervalRef = useRef(null);

  // 添加新的状态来存储所有语言的字幕
  const [allSubtitles, setAllSubtitles] = useState({});

  const Subtitle = ({ text, settings }) => (
    <Typography
      sx={{
        position: 'absolute',
        [settings.position]: '10%',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: `${fontSizes[settings.size]}px`,
        fontWeight: 'bold',
        color: settings.color,
        backgroundColor: settings.backgroundColor,
        padding: 2,
        textAlign: 'center',
        width: '100%',
        lineHeight: 1,
        textShadow: '2px 2px 4px rgba(0,0,0)',
        zIndex: 2
      }}
    >
      {text}
    </Typography>
  );


  const reportPlayback = () => {
    // 这里实现向后端发送播放记录的逻辑
    console.log("reportPlayback", videoRef.current.currentTime)
    if (videoRef.current.currentTime >= 10) {
      console.log("currentEpisodeDetailRef.current", currentEpisodeDetailRef.current)
      const episodeDetail = currentEpisodeDetailRef.current
      const sessionId = Cookies.get('sessionId')
      if (sessionId) {
        reportPlayRecord(episodeDetail.dramaDetail.id, videoRef.current.currentTime)
      } else {
        episodeDetail['playTime'] = videoRef.current.currentTime
        LocalStorageContext.pushItem("watchHistory", episodeDetail)
      }
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener('loadstart', handleLoadStart);
      video.addEventListener('canplay', handleCanPlay);
      video.addEventListener('waiting', handleWaiting);
      video.addEventListener('playing', handlePlaying);
      video.addEventListener('stalled', handleStalled);
      video.addEventListener('seeking', handleSeeking);
      video.addEventListener('seeked', handleSeeked);
      video.addEventListener('play', handlePlay);
      video.addEventListener('pause', handlePause);
      video.addEventListener('ended', handlePlayEnd);
    }

    return () => {
      if (video) {
        video.removeEventListener('loadstart', handleLoadStart);
        video.removeEventListener('canplay', handleCanPlay);
        video.removeEventListener('waiting', handleWaiting);
        video.removeEventListener('playing', handlePlaying);
        video.removeEventListener('stalled', handleStalled);
        video.removeEventListener('seeking', handleSeeking);
        video.removeEventListener('seeked', handleSeeked);
        video.removeEventListener('play', handlePlay);
        video.removeEventListener('pause', handlePause);
        video.removeEventListener('ended', handlePlayEnd);
        if (reportIntervalRef.current) {
          clearInterval(reportIntervalRef.current);
        }
      }
    };
  }, [activeIndex, dramas]);

  useEffect(() => {
    const clearAndLoadSubtitles = async () => {
      // 先清除所有字幕相关状态
      setParsedSubtitles([]);
      setCurrentSubtitle('');
      setAllSubtitles({});

      // 确保状态已更新后再加载新字幕
      if (dramas.length > activeIndex && dramas[activeIndex]?.firstOrderSubtitles) {
        try {
          // 加载所有可用语言的字幕
          const subtitlePromises = Object.entries(dramas[activeIndex].firstOrderSubtitles)
            .map(async ([lang, url]) => {
              const vttString = await fetchSubtitles(url);
              const parsedSubs = parseVTT(vttString);
              return [lang, parsedSubs];
            });

          const results = await Promise.all(subtitlePromises);
          const subtitlesObj = Object.fromEntries(results);
          setAllSubtitles(subtitlesObj);
          
          // 设置当前语言的字幕
          if (subtitlesObj[subtitleLang]) {
            setParsedSubtitles(subtitlesObj[subtitleLang]);
          }
        } catch (error) {
          console.error('Error in subtitle processing:', error);
        }
      }
    };

    clearAndLoadSubtitles();

  }, [activeIndex, dramas]);


  const fetchSubtitles = async (url) => {
    try {
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'omit', // 不发送凭证
        mode: 'cors', // 明确指定 CORS 模式
      });

      if (!response.ok) {
        throw new Error(
          `HTTP error! status: ${response.status}`);
      }

      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('text/vtt')) {
        console.warn('Unexpected content type:', contentType);
      }

      const vttString = await response.text();
      return vttString;
    } catch (error) {
      console.error('Failed to fetch subtitles:', error);
      throw error;
    }
  };

  const handleLoadStart = () => {
    updateLoadingState('isInitialLoading', true);
  };

  const handleCanPlay = () => {
    if (videoRef.current?.readyState >= 3) {
      updateLoadingState('isInitialLoading', false);
    }
  };

  const handleWaiting = () => {
    updateLoadingState('isBuffering', true);
  };

  const handlePlaying = () => {
    updateLoadingState('isBuffering', false);
    updateLoadingState('isInitialLoading', false);
  };

  const handleStalled = () => {
    updateLoadingState('isBuffering', true);
  };

  const handleSeeking = () => {
    updateLoadingState('isSeeking', true);
  };

  const handleSeeked = () => {
    updateLoadingState('isSeeking', false);
  };

  useEffect(() => {
    // 重置所有加载状态
    setLoadingStates({
      isBuffering: false,
      isSeeking: false,
      isInitialLoading: true
    });
  }, [activeIndex, dramas]);

  const handlePlay = () => {
    if (reportIntervalRef.current) {
      clearInterval(reportIntervalRef.current);
    }
    let hasReported = false;
    reportIntervalRef.current = setInterval(() => {
      if (videoRef.current.currentTime >= 10 && !hasReported) {
        reportPlayback();
        hasReported = true;
        clearInterval(reportIntervalRef.current);
      }
    }, 2000);
  };

  const handlePause = () => {
    if (reportIntervalRef.current) {
      clearInterval(reportIntervalRef.current);
      reportIntervalRef.current = null;
    }
  };


  // 处理播放结束的函数
  const handlePlayEnd = () => {
    // 在这里调用playEnd回调函数或执行其他操作
    playEnd();
  };


  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.clientHeight;
        const width = containerHeight * ratio;
        setVideoSize({ width, height: containerHeight });
      }
    };

    updateSize();
    window.addEventListener('resize', updateSize);

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [ratio]);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener('timeupdate', handleTimeUpdate);
      video.addEventListener('loadedmetadata', handleLoadedMetadata);
      // 添加这个事件监听器
      video.addEventListener('loadeddata', () => {
        console.log('Video loaded, duration:', video.duration);
        setDuration(video.duration);
      });
    }

    return () => {
      if (video) {
        video.removeEventListener('timeupdate', handleTimeUpdate);
        video.removeEventListener('loadedmetadata', handleLoadedMetadata);
        video.removeEventListener('loadeddata', () => { });
      }
    };
  }, []);



  useEffect(() => {
    const updateSubtitle = () => {
      if (parsedSubtitles && parsedSubtitles.length > 0) {
        const currentTime = videoRef.current.currentTime;
        const subtitle = parsedSubtitles.find(
          (sub) => currentTime >= sub.start && currentTime <= sub.end
        );
        setCurrentSubtitle(subtitle ? subtitle.text.trim() : '');
      }
    };

    const video = videoRef.current;
    if (video) {
      video.addEventListener('timeupdate', updateSubtitle);
    }

    return () => {
      if (video) {
        video.removeEventListener('timeupdate', updateSubtitle);
      }
    };
  }, [parsedSubtitles]);


  const parseVTT = (vttString) => {
    const lines = vttString.split('\n');
    const cues = [];
    let cue = null;

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();

      if (line.includes('-->')) {
        // 时间轴行
        const [start, end] = line.split(' --> ').map((time) => {
          const [hours, minutes, seconds] = time.split(':');
          return parseFloat(hours) * 3600 + parseFloat(minutes) * 60 + parseFloat(seconds);
        });
        cue = { start, end, text: '' };
      } else if (cue && line !== '') {
        // 字幕文本行
        cue.text += (cue.text ? '\n' : '') + line;
      } else if (cue && line === '') {
        // 空行，表示一个字幕块结束
        cues.push(cue);
        cue = null;
      }
      // 忽略其他行（如索引行）
    }

    // 处理最后一个字幕块
    if (cue) {
      cues.push(cue);
    }

    return cues;
  };

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
    setCurrentPlayTime(currentTime)
  };

  const handleLoadedMetadata = () => {
    setDuration(videoRef.current.duration);
  };

  const togglePlay = async () => {
    try {
      const video = videoRef.current;
      if (!video) return;

      if (isPlaying) {
        // 如果正在播放，先更新状态再暂停
        setIsPlaying(false);
        await video.pause();
      } else {
        // 如果已暂停，先尝试播放再更新状态
        const playPromise = video.play();
        if (playPromise !== undefined) {
          try {
            await playPromise;
            setIsPlaying(true);
          } catch (error) {
            // 如果播放被中断，保持暂停状态
            console.warn('播放被中断:', error);
            setIsPlaying(false);
          }
        }
      }
    } catch (error) {
      console.warn('播放状态切换失败:', error);
      // 确保状态与实际播放状态同步
      const actuallyPlaying = !videoRef.current?.paused;
      setIsPlaying(actuallyPlaying);
    }
  };

  // 在组件顶部添加新的状态
  const [activePopup, setActivePopup] = useState(null);

  // 修改 setShowSpeedOptions 和 setShowVolumeSlider 的调用
  const toggleSpeedOptions = () => {
    if (activePopup === 'speed') {
      setActivePopup(null);
    } else {
      setActivePopup('speed');
    }
  };

  const toggleVolumeSlider = () => {
    if (activePopup === 'volume') {
      setActivePopup(null);
    } else {
      setActivePopup('volume');
    }
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    videoRef.current.volume = newValue;
    setIsMuted(newValue === 0);
  };

  // 修改 handleSpeedChange 函数
  const handleSpeedChange = (newSpeed) => {
    setPlaybackRate(newSpeed);
    videoRef.current.playbackRate = newSpeed;
    toggleSpeedOptions();
  };

  const toggleLoop = () => {
    setIsLooping(!isLooping);
    videoRef.current.loop = !isLooping;
  };

  const toggleSubtitleSettings = () => {
    setShowSubtitleSettings(!showSubtitleSettings);
  };

  const updateSubtitleSettings = (setting, value) => {
    if (setting === "language") {
      setSubtitleLang(value);
      // 直接从已加载的字幕中切换
      if (allSubtitles[value]) {
        setParsedSubtitles(allSubtitles[value]);
      }
    }
    setSubtitleSettings(prev => ({ ...prev, [setting]: value }));
  };

  const enterPictureInPicture = async () => {
    try {
      if (document.pictureInPictureElement) {
        await document.exitPictureInPicture();
      } else {
        await videoRef.current.requestPictureInPicture();
      }
    } catch (error) {
      console.error('Picture-in-Picture failed:', error);
    }
  };

  const enterFullscreen = () => {
    if (!document.fullscreenElement) {
      containerRef.current.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleCommentChange = (event) => {
    setCommentContent(event.target.value);
  };

  const handleCommentSubmit = async () => {
    if (commentContent.trim() !== '') {
      const response = await comment(dramas[activeIndex].dramaId, commentContent)
      if (response) {
        const commonts = await fetchComments(dramas[activeIndex].dramaId)
        if (commonts && commonts.data) {
          setComments(commonts.data)
        }
        setCommentContent('')
      }
    }
  };

  const [clickTimeout, setClickTimeout] = useState(null);

  const handleDoubleClick = async () => {
    await togglePlay();
  };

  const handlePlayClick = () => {
    if (clickTimeout !== null) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
      handleDoubleClick();
    } else {
      const timeout = setTimeout(() => {
        setClickTimeout(null);
      }, 300); // 减少延迟时间以提高响应速度
      setClickTimeout(timeout);
    }
  };


  const handleSlideChange = async (swipData) => {
    setVideoSwipe(false)
    // 停止当前视频的播放
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
    setIsPlaying(false); // 更新播放状态
    const isEnd = swipData.isEnd
    const activeIndex = swipData.activeIndex
    setActiveIndex(activeIndex)
    if (isEnd) {
      await loadMoreDrama()
    }
    setVideoSwipe(true)

    // 添加GA跟踪
    ReactGA.event({
      category: 'Video',
      action: 'Swipe',
      label: `Swiped to video ${dramas[activeIndex].name}`
    });
  }

  return (
    <>
      <Helmet>
        {dramas[activeIndex] && (
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "VideoObject",
              "name": dramas[activeIndex].name,
              "description": dramas[activeIndex].desc,
              "thumbnailUrl": dramas[activeIndex].imgUrl,
              "uploadDate": dramas[activeIndex].createdAt,
              "contentUrl": dramas[activeIndex].firstOrderDramaUrl,
              "duration": `PT${Math.floor(duration)}S`,
              "embedUrl": window.location.href,
            })}
          </script>
        )}
      </Helmet>
      <Box
        ref={containerRef}
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
        // 添加以下属性
        role="region"
        aria-label={dramas[activeIndex]?.name || 'Video player'}
        itemScope
        itemType="https://schema.org/VideoObject"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            touchAction: 'none', // 添加这一行
          }}>
          <Swiper
            direction={'vertical'}
            mousewheel={{
              enabled: true,
              sensitivity: 2,
              thresholdDelta: 40,
              thresholdTime: 1000
            }}
            keyboard={true}
            allowSlidePrev={videoSwipe}
            allowSlideNext={videoSwipe}
            modules={[Mousewheel, Keyboard]}
            lazyPreloadPrevNext={1}
            onSlideChange={(swiper) => handleSlideChange(swiper)}
            onSwiper={(swiper) => setSwiperInstance(swiper)}
            style={{ height: '100%', width: '100%' }}
          >
            {dramas.map((item, index) => (
              <SwiperSlide key={`${item.id}-${index}`}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                {/* 隐藏的 SEO 优化链接 */}
                <a
                  href={`/drama/${item.dramaId}`}
                  style={{
                    position: 'absolute',
                    width: '1px',
                    height: '1px',
                    padding: 0,
                    margin: '-1px',
                    overflow: 'hidden',
                    clip: 'rect(0, 0, 0, 0)',
                    whiteSpace: 'nowrap',
                    border: 0
                  }}
                  tabIndex="-1"
                  aria-hidden="true"
                >
                  {item.name} - Watch {item.name} on BingeShorts
                </a>
                {/* 隐藏的 SEO 优化文本 */}
                <Typography variant={index === activeIndex ? "h1" : "h6"} sx={{ position: 'absolute', width: '1px', height: '1px', padding: 0, margin: '-1px', overflow: 'hidden', clip: 'rect(0, 0, 0, 0)', whiteSpace: 'nowrap', border: 0 }} aria-hidden="true">
                  {item.name}
                </Typography>
                {index !== activeIndex ? (<img src={item.imgUrl} style={{
                  height: '100%',
                  objectFit: 'contain',
                  objectPosition: 'center',
                  scrollSnapAlign: 'start',
                  margin: '0 auto'
                }}></img>) : (<video
                  loading={index === activeIndex ? "eager" : "lazy"}
                  onLoadedMetadata={handleLoadedMetadata}
                  onTimeUpdate={handleTimeUpdate}
                  ref={index === activeIndex ? videoRef : null}
                  src={index === activeIndex ? item.firstOrderDramaUrl : undefined}
                  poster={item.imgUrl}
                  preload={index === activeIndex || index === activeIndex + 1 || index === activeIndex - 1 ? "auto" : "none"}
                  style={{
                    height: '100%',
                    objectFit: 'contain',
                    objectPosition: 'center',
                    scrollSnapAlign: 'start',
                    margin: '0 auto'
                  }}
                  playsInline
                  onClick={handlePlayClick}
                ></video>)}
                <Typography variant="body1" sx={{ position: 'absolute', width: '1px', height: '1px', padding: 0, margin: '-1px', overflow: 'hidden', clip: 'rect(0, 0, 0, 0)', whiteSpace: 'nowrap', border: 0 }} aria-hidden="true">
                  {item.desc}
                </Typography>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
        {!isPlaying &&
          (<Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1
          }}>
            <IconButton sx={{
              width: '100px',
              height: '100px',
              color: 'white',
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.4)'
              },
            }} onClick={togglePlay}>
              <PlayArrow sx={{ fontSize: 80 }} />
            </IconButton>
          </Box>)
        }

        {isLoading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 2
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <VideoBuffering />
              <Typography variant="body2" sx={{ color: 'white', mt: 2 }}>
                {loadingStates.isInitialLoading ? 'video loading...' :
                  loadingStates.isBuffering ? 'video buffering...' :
                    loadingStates.isSeeking ? 'video seeking...' : ''}
              </Typography>
            </Box>
          </Box>
        )}
        {currentSubtitle?.trim() && <Subtitle text={currentSubtitle} settings={subtitleSettings} />}
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          justifyContent: 'flex-start',
          backgroundColor: 'rgba(255, 255, 255, 0.4)',
          borderRadius: '6 6 0 0',
          position: 'absolute',
          bottom: 0,
          width: '100%',
          left: 0,
          right: 0,
          padding: 0,
          zIndex: 1
        }}>

          <Slider
            value={isNaN(currentTime) ? 0 : currentTime}
            max={isNaN(duration) ? 100 : duration}
            onChange={(e, newValue) => {
              videoRef.current.currentTime = newValue;
            }}
            sx={{
              width: '100%',
              height: 4,
              padding: '0 !important',
              '& .MuiSlider-rail': {
                display: 'none', // 隐藏背景轨道
              },
              '& .MuiSlider-track': {
                height: 4, // 设置进度条高度
                borderRadius: 2,
                backgroundColor: 'primary.main', // 设置进度条颜色
              },
              '& .MuiSlider-thumb': {
                width: 12,
                height: 12,
                '&:hover, &.Mui-focusVisible': {
                  boxShadow: 'none',
                },
              },
            }}
          />
          <Box sx={{ display: matches ? isPlaying ? 'none' : 'flex' : 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton size='small' sx={{ color: 'black' }} onClick={togglePlay}>
                {isPlaying ? <Pause /> : <PlayArrow />}
              </IconButton>
              <Typography variant="body2" sx={{ display: matches ? 'none' : 'flex', marginLeft: 1, color: 'black' }}>
                {formatTime(currentTime)} / {formatTime(duration)}
              </Typography>
              <InputBase
                sx={{ display: matches ? 'none' : 'flex', ml: 4, flex: 1 }}
                placeholder="Share Your Commonts..."
                inputProps={{ 'aria-label': 'share your commonts...' }}
                onChange={handleCommentChange}
                value={commentContent || ''}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleCommentSubmit();
                  }
                }}
              />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton size='small' sx={{ display: matches ? 'none' : 'flex', color: 'black' }} onClick={toggleLoop}>
                <Loop color={isLooping ? 'primary' : 'inherit'} />
              </IconButton>
              <Box sx={{ position: 'relative' }}>
                <IconButton size='small' sx={{ color: 'black' }} onClick={toggleSpeedOptions}>
                  <Speed />
                </IconButton>
                {activePopup === 'speed' && (
                  <Box sx={{
                    position: 'absolute',
                    bottom: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    borderRadius: '4px',
                    padding: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}>
                    {[0.25, 0.5, 1, 1.25, 1.5, 2].map((speed) => (
                      <Button
                        key={speed}
                        onClick={() => handleSpeedChange(speed)}
                        sx={{
                          color: playbackRate === speed ? 'primary.main' : 'white',
                          '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
                          width: '100%',
                          padding: '8px 8px',
                        }}
                      >
                        {speed}x
                      </Button>
                    ))}
                  </Box>
                )}
              </Box>

              <Box sx={{ position: 'relative' }}>
                <IconButton size='small' sx={{ color: 'black' }} onClick={toggleVolumeSlider}>
                  {isMuted ? <VolumeOff /> : <VolumeUp />}
                </IconButton>
                {activePopup === 'volume' && (
                  <Box sx={{
                    position: 'absolute',
                    bottom: '100%',
                    height: 146,
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    borderRadius: 1,
                    paddingTop: 2,
                    paddingBottom: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}>
                    <Slider
                      value={isMuted ? 0 : volume}
                      onChange={handleVolumeChange}
                      orientation="vertical"
                      min={0}
                      max={1}
                      step={0.1}
                      sx={{
                        height: '100%',
                      }}
                    />
                  </Box>
                )}
              </Box>
              <IconButton size='small' sx={{ color: 'black' }} onClick={toggleSubtitleSettings}>
                <Subtitles />
              </IconButton>
              <IconButton size='small' sx={{ display: matches ? 'none' : 'flex', color: 'black' }} onClick={enterPictureInPicture}>
                <PictureInPicture />
              </IconButton>
              <IconButton size='small' sx={{ display: matches ? 'none' : 'flex', color: 'black' }} onClick={enterFullscreen}>
                <Fullscreen />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Modal
          open={showSubtitleSettings}
          onClose={toggleSubtitleSettings}
          aria-labelledby="subtitle-settings-modal"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}>
            <Typography id="subtitle-settings-modal" variant="h6" component="h2">
              Subtitle Settings
            </Typography>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Position</InputLabel>
              <Select
                value={subtitleSettings.position}
                onChange={(e) => updateSubtitleSettings('position', e.target.value)}
              >
                <MenuItem value="top">Top</MenuItem>
                <MenuItem value="bottom">Bottom</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Size</InputLabel>
              <Select
                value={subtitleSettings.size}
                onChange={(e) => updateSubtitleSettings('size', e.target.value)}
              >
                <MenuItem value="Small">Small</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="Large">Large</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Color</InputLabel>
              <Select
                value={subtitleSettings.color}
                onChange={(e) => updateSubtitleSettings('color', e.target.value)}
              >
                <MenuItem value="#ffffff">White</MenuItem>
                <MenuItem value="#ffff00">Yellow</MenuItem>
                <MenuItem value="#00ff00">Green</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Background Color</InputLabel>
              <Select
                value={subtitleSettings.backgroundColor}
                onChange={(e) => updateSubtitleSettings('backgroundColor', e.target.value)}
              >
                <MenuItem value="rgba(0, 0, 0, 0.5)">Semi-transparent Black</MenuItem>
                <MenuItem value="rgba(0, 0, 0, 0.8)">Deep Black</MenuItem>
                <MenuItem value="rgba(255, 255, 255, 0.5)">Semi-transparent White</MenuItem>
                <MenuItem value="rgba(0, 0, 0, 0)">Transparent</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Language</InputLabel>
              <Select
                value={subtitleLang}
                onChange={(e) => updateSubtitleSettings('language', e.target.value)}
              >
                {dramas[activeIndex] && dramas[activeIndex].firstOrderSubtitles &&
                  Object.keys(dramas[activeIndex].firstOrderSubtitles).map((lang) => (
                    <MenuItem key={lang} value={lang}>
                      {lang}
                    </MenuItem>
                  ))
                }

              </Select>
            </FormControl>
            <Button onClick={toggleSubtitleSettings} sx={{ mt: 2 }}>
              Close
            </Button>
          </Box>
        </Modal>

      </Box>

    </>
  );
};

export default VideoPlayer;
