import React, { useEffect,useState,useRef } from 'react';
import { Routes, Route } from 'react-router-dom';
import { CircularProgress, Alert, Box, useMediaQuery, Drawer } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AppBarComponent from '../components/AppBarComponent';
import LeftSidebar from '../components/LeftSidebar';
import MainContent from '../components/MainContent';
import Footer from '../components/Footer';
import ViewModel from '../services/ViewModel';
import { useDrama } from '../contexts/DramaContext';


const HomePage = () => {

  const viewModel = ViewModel(false);
  const { isLoading, error, setError } = viewModel;
  const { isDrawerOpen, toggleDrawer } = useDrama();

  // 使用useMediaQuery来检测屏幕宽度
  const matches = useMediaQuery('(max-width:600px)');

  const [contentHeight, setContentHeight] = useState('88vh');
  const appBarRef = useRef(null);
  const footerRef = useRef(null);


  useEffect(() => {
    const calculateHeight = () => {
      const appBarHeight = appBarRef.current?.offsetHeight || 0;
      const footerHeight = (!matches && footerRef.current?.offsetHeight) || 0;
      const remainingHeight = `calc(100vh - ${appBarHeight}px - ${footerHeight}px)`;
      setContentHeight(remainingHeight);
    };

    calculateHeight();
    window.addEventListener('resize', calculateHeight);

    return () => window.removeEventListener('resize', calculateHeight);
  }, [matches]);


  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <GoogleOAuthProvider clientId="986741823231-brvrqd0rkabl8jrqus1o6a1uj32faofv.apps.googleusercontent.com">
      <Box sx={{ height: "100vh", width: "100vw", display: "flex", flexDirection: "column", overflow: "hidden", backgroundColor: "#0f0f0f" }}>
        <Box ref={appBarRef}>
          <AppBarComponent
            viewModel={viewModel}
          />
        </Box>

        <Box flex={1} display="flex" position="relative" sx={{ height: contentHeight, width: '100vw',backgroundColor:'#0f0f0f' }}>
          {matches ? (
            <Drawer
              anchor='left'
              open={isDrawerOpen}
              onClose={toggleDrawer}
              sx={{
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  pr:1,
                  backgroundColor: '#1c1c1c',
                },
              }}
            >
              <LeftSidebar role="presentation" sx={{ display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'center', backgroundColor: "#0f0f0f" }} />
            </Drawer>
          ) : 
          (<Box sx={{ display: 'flex', height: '100%', width: '10%', flexDirection: 'column', alignItems: 'center', backgroundColor: "#0f0f0f",padding:1 }}>
            <LeftSidebar/>
          </Box>
          )}
          <Box sx={{ display: 'flex', height: '100%', width: matches ? '100%' : '90%', backgroundColor: "#0f0f0f",overflow:'scroll' }}>
            <Routes>
              <Route path="/drama" element={<MainContent viewModel={viewModel} contentType="Hot" />} />
              <Route path="/updates" element={<MainContent viewModel={viewModel} contentType="Updates" />} />
              <Route path="/category" element={<MainContent viewModel={viewModel} contentType="Category" />} />
              <Route path="/subscriptions" element={<MainContent viewModel={viewModel} contentType="Subscriptions" />} />
              <Route path="/contact" element={<MainContent viewModel={viewModel} contentType="Contact" />} />
              <Route path="/" element={<MainContent viewModel={viewModel} contentType="Hot" />} />
            </Routes>
          </Box>
        </Box>

        {!matches && <Box ref={footerRef}> <Footer /> </Box>}

        {isLoading && (
          <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <CircularProgress />
          </Box>
        )}

        {error && (
          <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <Alert severity="error" onClose={() => setError(false)}>{error}</Alert>
          </Box>
        )}
      </Box>
    </GoogleOAuthProvider>
  );
};

export default HomePage;