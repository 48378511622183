import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, TextField, Button, useMediaQuery } from '@mui/material';
import { AttachEmail } from '@mui/icons-material';
import Cookies from 'js-cookie';

const ContactPage = ({ viewModel }) => {

    const {comment } = viewModel

    // 使用useMediaQuery来检测屏幕宽度
    const matches = useMediaQuery('(max-width:600px)');

    const theme = useTheme();

    const [feedback, setFeedback] = useState('');

    const handleSubmit = () => {
        if (feedback.trim() !== '') {
            let sessionId = Cookies.get('sessionId');
            if (sessionId === undefined) {
                sessionId = Math.floor(Math.random() * 2 ** 32);
            }
            comment("feedback_" + sessionId,feedback)
        }
    };

    return (
        <Box sx={{ display: 'flex', height: '100%', width: '100%', flexDirection: 'column', padding: 2, overflow: 'scroll' }}>
            <Typography variant="h4" sx={{ marginBottom: 2, color: 'white' }}>Contact Us</Typography>
            <Typography variant="h6" sx={{ marginTop:2,marginBottom: 2, color: 'white', display: 'flex', alignItems: 'center' }}>
                <AttachEmail />: <a href="mailto:bingeshorts9@gmail.com" style={{ color: theme.palette.primary.main, textDecoration: 'none', marginLeft: 3 }}>bingeshorts9@gmail.com</a>
            </Typography>
            <TextField
                label="Help Us Improve"
                multiline
                rows={10}
                variant="outlined"
                fullWidth
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                sx={{
                    marginBottom: 2,
                    width: matches ? '96%' : '40%', // 设置宽度
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'white', // 设置边框颜色为白色
                        },
                        '&:hover fieldset': {
                            borderColor: 'white', // 设置悬停时边框颜色为白色
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'primary.main', // 设置聚焦时边框颜色为白色
                        },
                    },
                    '& .MuiInputLabel-root': {
                        color: 'white', // 设置标签颜色为白色
                        '&.Mui-focused': {
                            color: 'primary.main', // 设置聚焦时标签颜色为primary颜色
                        },
                    },
                    '& .MuiInputBase-input': {
                        color: 'white', // 设置输入文本颜色为白色
                    },
                }}
            />
            <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ width: matches ? '96%' : '40%' }}>Submit</Button>
        </Box>
    );
};

export default ContactPage;
