import React, { useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, ListItemAvatar, Avatar, Divider } from '@mui/material';
import { format, parseISO } from 'date-fns';
import ReactGA from 'react-ga4';

const ContentList = ({ title, items, onClick }) => {
  const [contentItems, setContentItems] = useState(items);

  useEffect(() => {
    setContentItems(items);
  }, [items]);

  const handleContentClick = (content) => {
    ReactGA.event({
      category: 'History',
      action: 'Content Click',
      label: `Clicked content ${content.dramaId}`
    });
    onClick(content);
  };

  return (
    <Box sx={{ padding: 1 }}>
      <Typography variant="h4" sx={{ margin: 1, fontWeight: 'bold' }}>
        {title}
      </Typography>
      <Divider />
      <List>
        {contentItems && contentItems.map((item, index) => (
          <ListItem key={index} alignItems="flex-start" onClick={() => handleContentClick(item)} sx={{
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'action.selected',
            },
          }}>
            <ListItemAvatar>
              <Avatar src={item.imgUrl} variant="square" sx={{ width: 96, height: 128 }} />
            </ListItemAvatar>
            <Box
              sx={{
                height: 128,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                ml: 2
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                {item.dramaName}
              </Typography>
              {item.dramaDetail && item.dramaDetail.order && (
                <Typography variant="body1" color="textSecondary">
                  Saw {item.dramaDetail.order}th Episodes
                </Typography>
              )}
              {item.createdAt && (
                <Typography variant="body2" color="textSecondary" sx={{ paddingTop: 2 }}>
                  {format(parseISO(item.createdAt), 'yyyy-MM-dd HH:mm')}
                </Typography>
              )}
            </Box>
          </ListItem>

        ))}
      </List>
    </Box>
  );
};

export default ContentList;
