import { useEffect } from 'react';

export const useDocumentMeta = (title, description) => {
  useEffect(() => {
    document.title = title;
    document.querySelector('meta[name="description"]').setAttribute('content', description);
  }, [title, description]);

  const setDocumentMeta = ({ title, description }) => {
    document.title = title;
    document.querySelector('meta[name="description"]').setAttribute('content', description);
  };
  return { setDocumentMeta };
};